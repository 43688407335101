import { combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";
import { authReducer } from "./modules/auth/reducer";
import { profileReducer } from "./modules/profile/reducer";
import { childrenReducer } from "./modules/children/reducer";
import { studyZoneReducer } from "./modules/studyZone/reducer";
import { gameZoneReducer } from "./modules/gameZone/reducer";
import { PaymentReducer } from "./modules/payment/reducer";
import { mediaReducer } from './modules/media/reducer';
import { nativeReducer } from './modules/native/reducer';

export const rootReducer = combineReducers({
  auth: authReducer,
  profile: profileReducer,
  children: childrenReducer,
  studyZone: studyZoneReducer,
  gameZone: gameZoneReducer,
  payment: PaymentReducer,
  media: mediaReducer,
  native: nativeReducer,
  form: formReducer,
});

type RootReducerType = typeof rootReducer;
export type AppStateType = ReturnType<RootReducerType>; // main state type

type PropertiesTypes<T> = T extends { [key: string]: infer U } ? U : never;
export type InferActionTypes<
  T extends { [key: string]: (...args: any[]) => any }
> = ReturnType<PropertiesTypes<T>>;
