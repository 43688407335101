import React, { useMemo, useState } from "react";
import LevelElement from "../LevelElement";
import { ILevelsData } from "../../../../../store/modules/studyZone/types";
import { Authorization } from "../../../../../store/modules/auth/types";
import SuccessAlert from '../../../../../components/modals/SuccessAlert';
import { getUkrainianMonthName } from '../../../../helpers';

interface IProps {
  levels: ILevelsData[];
  choseLesson: (id: number) => void;
  auth: Authorization | null;
}
/**
 *
 * @param levels{Object} - Redux value. List of levels with pagination params from api.
 * @param choseLesson{function} - function in the parent component
 * @param auth{'authorized' || 'notAuthorized'} - Redux value. Show auth user or no
 * @returns {JSX.Element}
 * @constructor
 */

const LevelList: React.FC<IProps> = ({ levels, choseLesson, auth }) => {
  const [lockedAlertText, setLockedAlertText] = useState('')

  const onLockedClick = (openAt: string, isUnlocked: boolean ) => {
    if (auth === "authorized") {
      if (openAt && new Date () <  new Date(openAt)) {
        setLockedAlertText(`Цей рівень буде розблокований у ${getUkrainianMonthName(openAt)}`)
      } else  {
        setLockedAlertText(`Пройди попередні рівні щоб відкрити цей!`)
      }
    }
    if (auth === 'notAuthorized') {
      if (isUnlocked) {
        setLockedAlertText(`Пройди попередні рівні щоб відкрити цей!`)
      }
      if (!isUnlocked) {
        setLockedAlertText(`Зареєструйся для того щоб відкрити більше рівнів!`)
      }
    }
  }

  const getElement = useMemo(() => {
    if (levels) {
      return levels.map((item) => {
        return (
          <LevelElement
            item={item}
            key={item.id}
            auth={auth}
            onLockedClick={() => onLockedClick(item.openAt, item.isUnlocked)}
            choseLesson={choseLesson}
          />
        );
      });
    }

    return null;
  }, [levels, auth, choseLesson]);

  return <div className="grid-list">
    {lockedAlertText && (
      <SuccessAlert text={lockedAlertText} onClick={() => setLockedAlertText('')}/>
    )}

    {getElement}
  </div>;
};

export default LevelList;
