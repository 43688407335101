import { History } from "history";
import {
  getArcadeLevelsRequest,
  getCategoriesRequest,
  getGameListRequest,
  getGameRequest,
  saveArcadesProgressRequest, saveUnregisterArcadesProgressRequest,
  setLevelCompletedRequest,
} from "./api";
import {
	ActionsType,
	IArcadeLevel,
	ICategories,
	IGame,
	IGameList,
	IGetArcadeLevelsOptions,
	IGetArcadeLevelsParams,
	IGetCategoriesOptions,
	IGetCategoriesParams,
	IGetGameListOptions,
	IGetGameListParams,
	ISaveArcadesProgressParams,
  ISetLevelCompletedOptions,
  ISetLevelCompletedParams,
} from "./types";
import { Dispatch } from "redux";
import {
  animation_item,
  arcade_saving_progress, arcades_completed_levels,
  completed_level,
  isStatusOK,
  lessons_saving_progress
} from "../../../helpers";
import { ISaveProgress } from '../children/types';
import { saveProgressRequest } from '../children/api';

export const actions = {
  setLoading: (value: boolean) =>
    ({ type: "gameZone/GAME_ZONE_LOADING", value } as const),
  setCategories: (payload: ICategories[] | null) =>
    ({ type: "gameZone/SET_CATEGORIES", payload } as const),
  setGameList: (payload: IGameList[] | null) =>
    ({ type: "gameZone/SET_GAME_LIST", payload } as const),
  setGameData: (payload: IGame | null) =>
    ({ type: "gameZone/SET_GAME_DATA", payload } as const),
  setArcadeLevels: (payload: IArcadeLevel[] | null) =>
    ({ type: "gameZone/SET_ARCADE_LEVEL", payload } as const),
};

export const getCategories =
  ({ childId }: IGetCategoriesParams, { history }: IGetCategoriesOptions) =>
  async (dispatch: Dispatch<ActionsType>) => {
    try {
      dispatch(actions.setLoading(true));

      const { data, status } = await getCategoriesRequest({ childId });

      if (isStatusOK(status)) {
        dispatch(actions.setCategories(data));
      }
      if (!isStatusOK(status)) {
        history.push("/");
      }
    } catch (error: unknown) {
      console.log("Error getCategories", error);
      history.push("/");
    } finally {
      dispatch(actions.setLoading(false));
    }
  };

export const getGameList =
  (activeChildId: number, categoryId: number, history: History) =>
  async (dispatch: Dispatch<ActionsType>) => {
    try {
      dispatch(actions.setLoading(true));
      const { data, status } = await getGameListRequest(
        activeChildId,
        categoryId
      );

      if (isStatusOK(status)) {
        dispatch(actions.setGameList(data));
      }
      if (!isStatusOK(status)) {
        history.push("/");
      }
    } catch (error: unknown) {
      console.log("Error getGameList", error);
      history.push("/");
    } finally {
      dispatch(actions.setLoading(false));
    }
  };

export const getGameData =
  ({ childId, levelId }: IGetGameListParams, { history }: IGetGameListOptions) =>
  async (dispatch: Dispatch<ActionsType>) => {
    try {
      dispatch(actions.setLoading(true));

      const { status, data } = await getGameRequest({ childId, levelId });
      if (isStatusOK(status)) {
        // @ts-ignore
        dispatch(actions.setGameData(data));
      }
      if (!isStatusOK(status)) {
        history.push("/");
      }
    } catch (error: unknown) {
      console.log("Error getGameData", error);
      history.push("/");
    } finally {
      dispatch(actions.setLoading(false));
    }
  };

export const getArcadeLevels =
  (
    { path, activeChildId }: IGetArcadeLevelsParams,
    { history }: IGetArcadeLevelsOptions
  ) =>
  async (dispatch: Dispatch<ActionsType>) => {
    try {
      dispatch(actions.setLoading(true));

      const { data, status } = await getArcadeLevelsRequest({
        path,
        activeChildId,
      });

      if (data) {
        dispatch(actions.setArcadeLevels(data));
      }

      if (!isStatusOK(status)) {
        history.push("/");
        dispatch(actions.setLoading(false));
      }
    } catch (error: unknown) {
      console.log("Error getArcadeLevels", error);
      history.push("/");
    } finally {
      dispatch(actions.setLoading(false));
    }
  };

export const setLevelCompleted =
  (
    { childId, levelId, isCompleted, gameId, categoryId }: ISetLevelCompletedParams,
    { history }: ISetLevelCompletedOptions
  ) =>
  async (dispatch: Dispatch<ActionsType>) => {
    try {
      dispatch(actions.setLoading(true));

      const { status } = await setLevelCompletedRequest({ childId, levelId });

      if (isStatusOK(status) && !isCompleted) {
        sessionStorage.setItem(animation_item, levelId);
      }
    } catch (error: unknown) {
      console.log('Error setLevelCompleted', error);

    } finally {
      dispatch(actions.setLoading(false));
			history.push(`/game-categories/${categoryId}/game/${gameId}`);
    }
  };

/**
 * POST method. Save progress for one Arcade.
 *
 */
export const saveArcadeProgress =
  (params: ISaveArcadesProgressParams) =>
  async (dispatch: Dispatch<ActionsType>) => {
    try {
      await saveArcadesProgressRequest(params);
    } catch (error: unknown) {
      console.log("Error saveLessonProgress ", error);
    }
  };

/**
 * POST method. Save arcade's child progress when child completed levels in not authorized state
 *
 * @param childId {number} - Id of child
 * @param params {Object} - Object with id of lesson what completed.
 * @returns {function(*): Promise<unknown>}
 */
export const saveUnregisterArcadesProgress =
  (childId: number, params: ISaveProgress) =>
    (dispatch: Dispatch<ActionsType>) => {
      return saveUnregisterArcadesProgressRequest(childId, params)
        .then((res: any) => {
          if (res.status === 200) {
            localStorage.removeItem(arcades_completed_levels);
            localStorage.removeItem(arcade_saving_progress);
          }
        })
        .catch((error: any) => {
          console.log(error);
        });
    };

