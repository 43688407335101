import React from 'react';
import { UnregisterSaveProgressMode } from '../constants/enums';
import { ILocation } from '../hooks/useHistoryWithReplace';
import { IGame } from '../store/modules/gameZone/types';
import { ILesson, ILocalSavingProgress } from '../store/modules/studyZone/types';
import { getLocalSavingProgress } from './index';
import { Authorization } from '../store/modules/auth/types';


interface ISetSavedPartIdParams {
	auth: Authorization | null
	mode: UnregisterSaveProgressMode
	state: ILocation
	data: IGame | ILesson | null
	id: string
}

interface ISetSavedPartIdOptions {
	setPartsCount:  React.Dispatch<React.SetStateAction<number>>
	replaceStore: (key: string) => void
}

export const setSavedPartId = (
	{auth, mode, state, data, id}: ISetSavedPartIdParams,
	{setPartsCount, replaceStore}: ISetSavedPartIdOptions) => {
	// Needs check if partId is Null. That's trigger if user clicked on start lesson/level from start when have savedProgress.
	if (!data || state?.partId === null) {
		replaceStore('partId');
		return
	}

	if (auth === 'authorized' && (state?.partId || data.savedPartId) ) {
		const partIndex = data.parts.findIndex(part => part.id === data.savedPartId);
		if (partIndex !== -1) {
			setPartsCount(partIndex)
		}
	}

	if (auth === 'notAuthorized') {
		const localSavingProgress: ILocalSavingProgress | null = getLocalSavingProgress(mode);
		if (localSavingProgress) {
			const partIndex = data.parts.findIndex(part => part.id === localSavingProgress[+id]);
			if (partIndex !== -1) {
				setPartsCount(partIndex)
			}
		}
	}
	replaceStore('partId');
}
