import React, { useState } from 'react';
import { ReactComponent as PlayIcon } from "../../../assets/icons/qr_laptop.svg";
import './index.scss'
import LaptopQrModal from '../../modals/LaptopQrModal';
import { isiOS } from '../AppStore';

const isLaptop = () => {
  const userAgent = navigator.userAgent.toLowerCase();
  const isMobile = /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini|mobile/i.test(userAgent);
  return !isMobile
};

const LaptopQR = () => {
  const [isModal, setIsModal] = useState(false);
  if (!isLaptop() || isiOS()) {
    return null;
  }

  return (
    <>
      {isModal && <LaptopQrModal onClose={() => setIsModal(false)}/>}
      <div className="laptop-qr" onClick={() => setIsModal(true)}>
        <PlayIcon/>
      </div>
    </>
  );
};

export default LaptopQR;
