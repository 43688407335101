import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Slider from "react-slick";
import { connect } from "react-redux";
import RoundButton from "../../components/buttons/RoundButton";
import home from "../../assets/icons/home.svg";
import { sliderSettings } from "./constants";
import BookElement from "./components/BookElement";
import { getIsPortrait, getOrientation } from "../../helpers";
import { getLearnBook } from "../../store/modules/studyZone/actions";
import gameZoneSelector from "../../store/modules/studyZone/selector";
import childrenSelector from "../../store/modules/children/selector";
import childLoader from "../../assets/icons/loaders/preloader_2_for_CSS-1.svg";
import { ILearnBook } from "../../store/modules/studyZone/types";
import { AppStateType } from "../../store/rootReducer";
import { History } from "history";
import "./bookOfStars.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

interface IMapState {
  booksOfLearn: ILearnBook[] | null;
  loading: boolean;
  childId: number | null;
}

interface IMapDispatch {
  getLearnBook: (childId: number | null, history: History) => void;
}

type Props = IMapState & IMapDispatch;

const BookOfStart: React.FC<Props> = ({
  getLearnBook,
  booksOfLearn,
  loading,
  childId,
}) => {
  const [orientation, setOrientation] = useState(getOrientation());

  const history = useHistory();

  useEffect(() => {
    changeOrientation();

    const body = document.getElementsByTagName("body")[0];
    body.classList.add("overflow-hidden");

    return () => {
      body.classList.remove("overflow-hidden");
      window.removeEventListener("resize", resizeEvent);
    };
  }, []);

  useEffect(() => {
    // if (childId) {
    //   getLearnBook(childId, history);
    // }

    const id = childId ? childId : 0

    getLearnBook(id, history);

  }, [childId]);

  const resizeEvent = () => {
    setOrientation(getOrientation());
  };

  const changeOrientation = () => {
    window.addEventListener("resize", resizeEvent);
  };

  const backHome = () => {
    history.push("/home");
  };

  const getBooks = () => {
    if (booksOfLearn) {
      return booksOfLearn.map(({ id, preview, file }) => (
        <BookElement image={preview} key={id} file={file} />
      ));
    }

    return null;
  };

  if (loading || !booksOfLearn) {
    return (
      <div className="book-star__container flex-center">
        <img src={childLoader} alt="loading" className="loader-icon" />
      </div>
    );
  }

  return (
    <>
      <div className="vertical-screen">
        <div className="book-star__container flex-center ">
          <div className="book-star__element-list">
            <Slider
              {...sliderSettings}
              verticalSwiping={getIsPortrait(orientation)}
            >
              {getBooks()}
            </Slider>
          </div>
        </div>
        <RoundButton
          image={home}
          onClick={backHome}
          alt="home"
          className="position__left-bottom lessons-nav_circle"
          imageClassName="lessons-nav_circle-house lessons-nav-image"
        />
      </div>
    </>
  );
};

const mapStateToProps = (state: AppStateType): IMapState => ({
  loading: gameZoneSelector.getLoading(state),
  booksOfLearn: gameZoneSelector.getBooksOfLearn(state),
  childId: childrenSelector.getActiveChildId(state),
});

const mapDispatchToProps = {
  getLearnBook,
};

export default connect(mapStateToProps, mapDispatchToProps)(BookOfStart);
