import request from "../../request";
import {
  IResetProfilePassword,
  ISetCertificate,
  ISetEmail,
  ISetKeyword,
} from "./types";
import { ICode } from '../../../pages/settings/VerifyPhone';

export const getProfileRequest = () => {
  return request.get("profile");
};

export const setKeywordRequest = (params: ISetKeyword) => {
  return request.patch("profile/key-word", params);
};

export const resetProfilePasswordRequest = (params: IResetProfilePassword) => {
  return request.patch("profile/password", params);
};

export const setEmailRequest = (params: ISetEmail) => {
  return request.patch("profile/email", params);
};

export const resendEmailRequest = () => {
  return request.post("email/resend");
};

export const resetKeywordRequest = () => {
  return request.post("profile/reset-keyword");
};

export const triggerSendSMSRequest = () => {
  return request.post("phone/send-verify-code");
};

export const sendTelephoneCodeRequest = (params: ICode) => {
  return request.post("profile/verify-phone", params);
};

export const changeNumberRequest = (params: any) => {
  return request.post("profile/update-phone", params);
};

export const setCertificateRequest = (
  activeChildId: number | null,
  params: ISetCertificate
) => {
  return request.post(`leads/send-to-crm/${activeChildId}`, params);
};
