import React from "react";
import { useTranslation } from "react-i18next";
import download from "../../../../assets/icons/download.svg";

interface Props {
  image: string;
  file: string;
}

/**
 *
 * @param image{File} - Background Image
 * @param file{File} - Background Image
 * @returns {JSX.Element}
 * @constructor
 */
const BookElement: React.FC<Props> = ({ image, file }) => {
  const { t } = useTranslation();
  return (
    <div className="book-star__element">
      <img src={image} alt="" className="book-star__element-img" />
      <div className="book-star__element-link-container flex-center">
        <div className="book-star__element-link">
          <a
            className="book-star__element-text"
            href={file}
            target="_blank"
            rel="noreferrer"
            download
          >
            <img
              src={download}
              className="book-star__element-link-img"
              alt="download"
            />
            {t("BookOfList.downloadText")}
          </a>
        </div>
      </div>
    </div>
  );
};

export default BookElement;
