import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import gameZoneSelector from "../../../store/modules/gameZone/selector";
import childrenSelector from "../../../store/modules/children/selector";
import RoundButton from "../../../components/buttons/RoundButton";
import home from "../../../assets/icons/home.svg";
import childLoader from "../../../assets/icons/loaders/preloader_2_for_CSS-1.svg";
import { getCategories, actions, } from "../../../store/modules/gameZone/actions";
import { History } from "history";
import "./index.scss";
import useMediaPath from '../../../hooks/useMediaPath';
import { MediaPathMode } from '../../../constants/enums';

/**
 * List of game's categories
 *
 * @returns {JSX.Element}
 * @constructor
 */
const GameCategories = () => {
  const history: History = useHistory();
  const dispatch = useDispatch();
  const path = useMediaPath(MediaPathMode.ArcadeCategories);

  const { categories, loading } = useSelector(gameZoneSelector.getGameStore);
  const activeChildId = useSelector(childrenSelector.getActiveChildId);

  useEffect(() => {
    // if (activeChildId) {
    //   getCategories(activeChildId, history);
    // }

    // We use 0 because initially the zone was supposed to be only for registered Users
    const childId = activeChildId ? activeChildId : 0;
    dispatch(getCategories({ childId }, { history }));

    return () => {
      dispatch(actions.setCategories(null));
    };
  }, [activeChildId]);

  const getCategoriesList = () => {
    if (categories) {
      return categories.map(({ id, image }) => (
        <div
          key={id}
          className="category__element"
          onClick={() => history.push(`game-categories/${id}`)}
          onKeyDown={() => history.push(`game-categories/${id}`)}
        >
          <img src={image} alt="category" className="category__image" />
        </div>
      ));
    }

    return null;
  };

  if (loading || !categories) {
    return (
      <div className="vertical-screen"  style={{
        background: `center/cover no-repeat url(${path}), linear-gradient(77.19deg, #FFA31F 0%, #FFE603 100%)`,
      }}>
        <div
          className="game-categories__container flex-center">
          <img src={childLoader} alt="loading" className="loader-icon" />
        </div>
      </div>
    );
  }

  return (
    <div className="vertical-screen" style={{
      background: `center/cover no-repeat url(${path}), linear-gradient(77.19deg, #FFA31F 0%, #FFE603 100%)`,
    }}>
      <div className="game-categories__container flex-center"
          style={{backdropFilter: path?.includes('small') ?  'blur(5px)' : 'none'}}
      >
        <div className="game-categories__list">{getCategoriesList()}</div>
        <RoundButton
          image={home}
          onClick={() => {
            history.push("/home");
          }}
          alt="home"
          className="position__left-bottom lessons-nav_circle"
          imageClassName="lessons-nav_circle-house lessons-nav-image"
        />
      </div>
    </div>
  );
};

export default GameCategories;
