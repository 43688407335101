import React, { useEffect, useState } from 'react';
import { getOrientation } from "../helpers";

const UseOrientation = () => {
    const [orientation, setOrientation] = useState(getOrientation())

    const changeSize = () => {
        setOrientation(getOrientation())
    }

    useEffect(() => {
        window.addEventListener("resize", changeSize, false);

        return () => {
            window.removeEventListener("resize", changeSize, false);
        }
    },[])

    return [orientation];
};

export default UseOrientation;
