import React, { useRef } from "react";
import ReactPlayer from "react-player";
import { OnProgressProps } from 'react-player/base';

interface IProps {
  completedPart: () => void;
  source: string;
}

const config = {
  controls: true,
  playing: true,
  width: "100%",
  height: "100%",
  style: {
    width: "100%",
    height: "100%",
    background: "#000000"
  },
  config: {
    youtube: {
      playerVars: { controls: 1 },
    },
    vimeo: {
      playerOptions: {
        autoplay: true,
        // responsive: true,
        portrait: false,
      }
    },
  },
};


/**
 *  Component when start video from youTube
 *
 * @param completedPart {function} - Function what completed parts.
 * @param source {string}  - string with video.
 * @returns {JSX.Element}
 * @constructor
 */
const VideoModule: React.FC<IProps> = ({ completedPart, source }) => {
  const player = useRef(null);

  // TODO need to change because source is string
  const link = JSON.parse(source).src;

  const onEnded = () => {
    completedPart();
  }

  const onProgress = (state: OnProgressProps) => {
    if (state.played && state.played > 0.995 ) {
      onEnded();
    }
  }

  return (
    <div className="vertical-screen">
      <div style={{ width: "100%", height: "100%", overflow: "hidden" }}>
        <ReactPlayer
          url={link}
          ref={player}
          onEnded={onEnded}
          onProgress={onProgress}
          {...config}
        />
      </div>
    </div>
  );
};

export default VideoModule;
