import request from "../../request";
import { ISubAccessesRequest } from "./types";

export const getCourseSubRequest = (childId: number, courseId: number) => {
  return request.get(`payments/get-plans/${courseId}`, { childId });
};

export const getSubAccessesRequest = (
  childId: number,
  courseId: number,
  action: string,
  params: ISubAccessesRequest | null
) => {
  return request.post(
    `subscription/${childId}/courses/${courseId}/${action}`,
    params
  );
};
export const getSubArcadeAccessRequest = (childId: number, action: string) => {
  return request.post(`subscription/${childId}/arcades/${action}`);
};
