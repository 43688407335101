import React, { FC, useEffect, useRef } from "react";
import cn from "classnames";
import emptyStar from "../../../../../assets/icons/emptyStar.svg";
import star from "../../../../../assets/icons/star.svg";
import Lock, { ILockHandle } from "../../../../../components/Lock";
import { IRedirectToGame } from "../../type";
import { animation_item } from '../../../../../helpers';

interface IArcadesLevelElement {
  name: string;
  isAvailable: boolean;
  isCompleted: boolean;
  levelId: number;
  savedPartId: number | null;
  onLevelClick: (params: IRedirectToGame) => void;
  isAnimated: boolean;
}

const ArcadesLevelElement: FC<IArcadesLevelElement> = ({
  name,
  isAvailable,
  isCompleted,
  levelId,
  savedPartId,
  onLevelClick,
  isAnimated,
}) => {
  const lockRef = useRef<ILockHandle>(null);

  useEffect(() => {
    if (isAvailable) {
      sessionStorage.removeItem(animation_item);
    }

  },[isAnimated])

  const getImage = () => {
    if (isAvailable) {
      return (
        <img
          src={isCompleted ? star : emptyStar}
          alt="cub"
          className={cn('grid-element-image', {
            'starAnimation': isAnimated && isCompleted,
          })}
        />
      );
    }
    return <Lock ref={lockRef} />;
  };

  const onClickHandler = () => {
    if (isAvailable) {
      onLevelClick({ levelId, savedPartId });
    }
    if (!isAvailable && lockRef.current) {
      lockRef.current.onStartAnimation();
    }
  };
  return (
    <div
      className="grid-element lessons-style flex-center"
      onClick={() => onClickHandler()}
      onKeyDown={() => onClickHandler()}
    >
      {getImage()}
      <div className="grid-element-text text-center">{name}</div>
    </div>
  );
};

export default ArcadesLevelElement;
