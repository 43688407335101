import React from "react";
import { Link } from 'react-router-dom';
import "./roundButton.scss";

interface IProps {
  onClick: (event: React.MouseEvent<HTMLElement>) => void;
  className: string;
  image: string | undefined;
  alt: string;
  imageClassName?: string;
}

/**
 *
 * @param onClick{function} - clickEvent
 * @param className{string} - className for container if need position or something else
 * @param image{File} - file. Simple import. Image for icon
 * @param alt{string} - Alt for image.
 * @param imageClassName{string} - ClassName for image if need correct position image in circle
 * @returns {JSX.Element}
 * @constructor
 */
const RoundButton: React.FC<IProps> = ({
  onClick,
  className = "",
  image,
  alt = "link",
  imageClassName,
}) => {
  const clazz = `round-button flex-center ${className}`;

  return (
    <div className={clazz} onClick={onClick}>
      <img className={imageClassName} src={image} alt={alt} />
    </div>
  );
};

export default RoundButton;
