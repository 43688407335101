import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import { Provider } from "react-redux";
import App from "./pages/App/app";
import * as serviceWorker from "./serviceWorker";
import { store } from "./store/store";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import "./i18n";
import { NodeENV } from './constants/enums';
import { GoogleOAuthProvider } from '@react-oauth/google';
import env from "@beam-australia/react-env";

env('NODE_ENV') === NodeENV.Production && Sentry.init({
  dsn: env('SENTRY_DNS'),
  integrations: [new BrowserTracing()],
  tracesSampleRate: 1.0,
});

ReactDOM.render(
  <Provider store={store}>
    <GoogleOAuthProvider clientId={env('GOOGLE_CLIENT_ID') || ''}>
      <Router basename="/">
        <App/>
      </Router>
    </GoogleOAuthProvider>
  </Provider>,
  document.getElementById("root")
);

// If you want your App to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
