import { AppStateType } from "../../rootReducer";

const getLessons = (state: AppStateType) => state.studyZone.lessonsList;
const getLesson = (state: AppStateType) => state.studyZone.lesson;
const getBooksOfLearn = (state: AppStateType) => state.studyZone.booksOfLearn;
const getLoading = (state: AppStateType) => state.studyZone.loading;
const getPrevComplete = (state: AppStateType) => state.studyZone.prevComplete;
const getCoursesDetails = (state: AppStateType) =>
  state.studyZone.coursesDetails;
const getLevels = (state: AppStateType) => state.studyZone.levels;
const getStudyStore = (state: AppStateType) => state.studyZone;

export default {
  getLevels,
  getLessons,
  getLesson,
  getPrevComplete,
  getCoursesDetails,
  getBooksOfLearn,
  getLoading,
  getStudyStore
};
