import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import profileSelector from "../../../store/modules/profile/selector";
import {
  checkKeyWord,
  getProfile,
  resetKeyword,
  actions,
} from "../../../store/modules/profile/actions";
import SuccessAlert from "../../../components/modals/SuccessAlert";
import SingleFieldPage from "../../../components/commonPages/SingleFieldPage";
import { minLengthCreator, requiredValidate } from "../../../assets/validation";
import { AppStateType } from "../../../store/rootReducer";
import { IProfile } from "../../../store/modules/profile/types";
import { History } from "history";
import { KeywordType } from "../../../types";

interface ISubmitForm {
  keyword: string;
}

interface IMapState {
  keyword: KeywordType;
  profile: IProfile | null;
  isShowSuccessAlert: boolean;
}

interface IMapDispatch {
  getProfile: (history: History) => void;
  checkKeyWord: (
    formValue: string,
    keyword: KeywordType,
    history: History,
    location: any
  ) => void;
  resetKeyword: () => void;
  setSuccessAlertProfile: (value: boolean) => void;
}

type Props = IMapState & IMapDispatch;

const minLength = minLengthCreator(3);

/**
 *
 * @param loading{boolean} - Redux value. Shows when need show loader
 * @param keyword{string | undefined | null} - Redux value. AddKeyword
 * @param getProfile{function} - Action. Get data of Profile.
 * @param profile{object} - Information of user Account
 * @param resetKeyword{function} - Action. Action for reset keyword. Return updated profile data
 * @param checkKeyWord{function} - Action. Checked whether the entered key word matches the key word
 * @param isShowSuccessAlert{boolean} - Redux value. Show needs show password or not
 * @param setSuccessAlertProfile{function} - Action. Set isShowSuccessAlert
 * @returns {JSX.Element}
 * @constructor
 */
const EnterKeyword: React.FC<Props> = ({
  keyword,
  getProfile,
  profile,
  resetKeyword,
  checkKeyWord,
  isShowSuccessAlert,
  setSuccessAlertProfile,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    if (!profile) {
      getProfile(history);
    }
  }, [getProfile]);

  const submitForm = (values: ISubmitForm) => {
    checkKeyWord(values.keyword, keyword, history, location);
  };

  const clickForget = () => {
    resetKeyword();
  };

  const clickBack = () => {
    history.push("/home");
  };

  const getResetKeywordModal = () => {
    if (isShowSuccessAlert) {
      return (
        <SuccessAlert
          onClick={() => setSuccessAlertProfile(false)}
          text={t("setting.EnterKeyword.alertMessage")}
        />
      );
    }

    return null;
  };

  return (
    <>
      {getResetKeywordModal()}

      <SingleFieldPage
        onSubmit={submitForm}
        title={t("setting.EnterKeyword.title")}
        placeholder="..."
        name="keyword"
        buttonText={t("setting.EnterKeyword.form.buttonText")}
        isBackButton
        clickBack={clickBack}
        isBlueButton
        blueButtonText={t("setting.EnterKeyword.blueButton")}
        blueClick={clickForget}
        validation={[requiredValidate, minLength]}
      />
    </>
  );
};

const mapStateToProps = (state: AppStateType): IMapState => ({
  keyword: profileSelector.getKeyWord(state),
  profile: profileSelector.getProfile(state),
  isShowSuccessAlert: profileSelector.getIsShowSuccessAlert(state),
});

const mapDispatchToProps: IMapDispatch = {
  getProfile,
  checkKeyWord,
  resetKeyword,
  setSuccessAlertProfile: actions.setSuccessAlertProfile,
};

export default connect(mapStateToProps, mapDispatchToProps)(EnterKeyword);
