import React, { useEffect, useRef, useState, memo } from "react";
import { useHistory, useLocation } from "react-router-dom";
import star from "../../../../../assets/icons/star.svg";
import emptyStar from "../../../../../assets/icons/emptyStar.svg";
import { Authorization } from "../../../../../store/modules/auth/types";
import { History } from "history";
import Lock, { ILockHandle } from '../../../../../components/Lock';
import { IOnLessonClick } from '../../type';
import login from '../../../../auth/Login';

interface ILocation {
	completedLessonId?: number;
}

interface IProps {
	levelId: number;
	id: number;
	completed?: boolean;
	availableForGuest?: boolean;
	available?: boolean;
	auth: Authorization | null;
	name: number;
	savedPartId: number | null;
	onLessonClick: ({ lessonId, partId }: IOnLessonClick) => void
	isScrollToMe: boolean
	onScrollTo: (top: number) => void
	onLockedClick: () => void
}

/**
 *
 * @param levelId{number} - Current id of level
 * @param id{number} - Current id of Lesson
 * @param completed{boolean} - Shows completed lesson or no.
 * @param onLessonClick{Function} - Function redirect user to new page.
 * @param availableForGuest{boolean} - Shows whether an unregistered user can play of the level.
 * @param available{boolean} - Shows whether an registered user can play of the level.
 * @param auth{'authorized' || 'notAuthorized'} - Shows when need to show loader. * @returns {JSX.Element}
 * @constructor
 */
const LessonElement: React.FC<IProps> = ({
																					 levelId,
																					 id,
																					 completed,
																					 available,
																					 auth,
																					 name,
																					 onLessonClick,
																					 savedPartId,
																					 isScrollToMe,
																					 onScrollTo,
																					 onLockedClick
																				 }) => {
	const myRef = useRef<HTMLDivElement>(null);
  const lockRef = useRef<ILockHandle>(null);
	const [clazzImage, setClazzImage] = useState("grid-element-image");
	const history: History = useHistory();
	const { state } = useLocation<ILocation>();

	useEffect(() => {
		const isUnregisterAnimate =
			+sessionStorage.getItem("animation_item")! === id;
		if (isUnregisterAnimate) {
			sessionStorage.removeItem("animation_item");
		}
		// get from history state id of level what completed. If id coincides then I add a class with animation and I delete id from history.state
		if (state?.completedLessonId === id || isUnregisterAnimate) {
			setClazzImage("grid-element-image starAnimation");
			// @ts-ignore
			if (history.location.state && history.location.state.completedLessonId) {
				// @ts-ignore
				const state: ILocation = { ...history.location.state };
				delete state.completedLessonId;
				history.replace({ ...history.location, state });
			}
		}
	}, []);

	useEffect(() => {
		if (isScrollToMe && myRef.current) {
			onScrollTo(myRef.current.offsetTop);
		}
	}, [isScrollToMe])

	const goToLesson = (levelId: number, lessonId: number) => {
		onLessonClick({ lessonId: lessonId, partId: savedPartId })
	};

	const getImage = () => {
		if (available) {
			if (auth === "authorized") {
				return (
					<img
						src={completed ? star : emptyStar}
						alt="star"
						className={clazzImage}
					/>
				);
			}

			if (auth === "notAuthorized") {
				if (localStorage.getItem("completed_level")) {
					const isStar = localStorage
						.getItem("completed_level")!
						.split(",")
						.some((localId) => +localId === id);

					return (
						<img
							src={isStar ? star : emptyStar}
							alt="star"
							className={clazzImage}
						/>
					);
				}

				return <img src={emptyStar} alt="star" className={clazzImage}/>;
			}
		}

    return (
      <Lock ref={lockRef}  />
    );
  };

  const clickElement = (levelId: number, id: number) => {
    if (available) {
      goToLesson(levelId, id);
    }
    if (!available && lockRef.current) {
      lockRef.current.onStartAnimation();
			onLockedClick()

    }
  };

	return (
		<div
			ref={myRef}
			className="grid-element lessons-style flex-center"
			onClick={() => clickElement(levelId, id)}
			onKeyDown={() => clickElement(levelId, id)}
		>
			{getImage()}
			<div className="grid-element-text text-center"> {name}</div>
		</div>
	);
};

export default memo(LessonElement);
