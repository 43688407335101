import { get } from "lodash";
import { stopSubmit } from "redux-form";
import { actions as actionsProfile } from "../profile/actions";
import { History } from "history";
import {
  addChildRequest,
  getChildDetailsRequest,
  getChildrenRequest,
  removeChildRequest,
  upgradeChildRequest,
  saveProgressRequest,
  getCertificateRequest,
} from "./api";
import {
  IChildRequest,
  IChildrenHeader,
  ISaveProgress,
  ActionsType,
} from "./types";
import { ActionsType as ActionsTypeProfile } from "../profile/types";
import { Dispatch } from "redux";
import { completed_level, lessons_saving_progress } from '../../../helpers';

export const actions = {
  setChildLogin: (value: boolean) =>
    ({ type: "children/CHILDREN_LOADING", value } as const),
  setActiveChild: (payload: any) =>
    ({ type: "children/SET_ACTIVE_CHILDREN", payload } as const),
  setActiveChildId: (childId: number) =>
    ({ type: "children/SET_ACTIVE_CHILD_ID", childId } as const),
  setChildren: (payload: any) =>
    ({ type: "children/SET_CHILDREN_LIST", payload } as const),
  setClearChildrenList: () =>
    ({ type: "children/CLEAR_CHILDREN_LIST" } as const),
};

export const clearChildrenList = () => (dispatch: Dispatch<ActionsType>) => {
  dispatch(actions.setClearChildrenList());
};
/**
 * GET method '/children'. Get list of children
 *
 * @param history {Object} - Hook from useHistory
 * @returns {function(*): Promise<unknown>}
 */
export const getChildren =
  (history: History) => (dispatch: Dispatch<ActionsType>) => {
    dispatch(actions.setChildLogin(true));

    return getChildrenRequest()
      .then((res: any) => {
        if (res.data) {
          dispatch(actions.setChildren(res.data));
          dispatch(actions.setChildLogin(false));
        }
        if (res.status === 401 || res.status === 403) {
          history.push("/");
        }
      })
      .catch((error: unknown) => {
        dispatch(actions.setChildLogin(false));
        console.log(error);
      });
  };

/**
 * DELETE method '/children/{id}'. Delete child from api.
 *
 * @param childId {number} - Child id
 * @param history {Object} - Hook from useHistory
 * @returns {function(*): Promise<unknown>}
 */
export const removeChild =
  (childId: number, history: History) =>
    (dispatch: Dispatch<ActionsType | ActionsTypeProfile>) => {
      dispatch(actions.setChildLogin(true));

      return removeChildRequest(childId)
        .then((res: any) => {
          if (res.status === 204) {
            dispatch(actions.setChildLogin(false));
            dispatch(actionsProfile.clearProfile());

            clearChildrenList();

            // @ts-ignore
            if (childId === +localStorage.getItem("active_child_id")) {
              localStorage.removeItem("active_child_id");
            }
            history.push("/cabinet");
          }
          if (res.status === 401 || res.status === 403 || res.status === 404) {
            history.push("/");
          }
        })
        .catch((error: unknown) => {
          console.log(error);
        });
    };

/**
 * GET method '/children/{id}' - Get details information about child.
 *
 * @param childId {number} - Child id
 * @param history {Object} - Hook from useHistory.
 * @returns {function(*): Promise<unknown>}
 */
export const getChildDetails =
  (childId: number, history: History) =>
    (dispatch: Dispatch<ActionsType>): void => {
      dispatch(actions.setChildLogin(true));

      return getChildDetailsRequest(childId)
        .then((res: any) => {
          if (res.status === 200) {
            dispatch(actions.setActiveChild(res.data));
            dispatch(actions.setChildLogin(false));
          }
          if (res.status === 404) {
            dispatch(actions.setChildLogin(false));
            history.push("/cabinet");
          }
          if (res.status === 401 || res.status === 403) {
            dispatch(actions.setChildLogin(false));
            history.push("/");
          }
        })
        .catch((error: unknown) => {
          dispatch(actions.setChildLogin(false));
          console.log(error);
        });
    };

/**
 * POST method '/children/{id}?_method=PUT' - Update existing child
 *
 * @param childId {number} - Id of child.
 * @param params {Object} - New params for child.
 * @param history {Object} - Hook from useHistory
 * @returns {function(*): Promise<unknown>}
 */
export const upgradeChild =
  (childId: number, params: IChildRequest, history: History) =>
    (dispatch: Dispatch<ActionsType | ActionsTypeProfile>) => {
      dispatch(actions.setChildLogin(true));

      const headers: IChildrenHeader = {
        accept: "application/json",
      };

      return upgradeChildRequest(childId, params, headers)
        .then((res: any) => {
          if (res.status === 202) {
            dispatch(actions.setChildLogin(false));
            dispatch(actionsProfile.clearProfile());
            dispatch(actions.setActiveChild(res.data));
            history.push(`/cabinet/children/${childId}`);
          }
        })
        .catch((error: unknown) => {
          console.log(error);
          dispatch(actions.setChildLogin(false));
        });
    };

/**
 * POST method '/children' - Store new child
 *
 * @param params {Object} - Params of new child.
 * @param history {Object} - Hook from useHistory.
 * @param keyWord {string} - Keyword needs for enter in cabinet. In this case, if there is no key word, then flip to the page to enter it.
 * @returns {function(*): Promise<unknown>}
 */
export const addChild =
  (
    params: IChildRequest,
    history: History,
    keyWord: string | null | undefined
  ): ((arg0: any) => Promise<unknown>) =>
    (dispatch: Dispatch<ActionsType | ActionsTypeProfile>) => {
      dispatch(actions.setChildLogin(true));

      const headers: IChildrenHeader = {
        accept: "application/json",
      };

      return addChildRequest(params, headers)
        .then((res: any) => {
          if (res.status === 201) {
            dispatch(actions.setChildLogin(false));
            dispatch(actions.setActiveChild(res.data));

            if (keyWord) {
              dispatch(actionsProfile.clearProfile());

              history.push({
                pathname: '/cabinet',
                // @ts-ignore
                state: { to: history.location.state?.to || "/" },
              });
            } else {
              dispatch(actionsProfile.clearProfile());
              history.push("/settings/add-keyword");
            }
          }

          if (res.status === 422) {
            const message = get(
              res,
              "error.message",
              "Сталося щось не те, але ми вже працюємо над цим"
            );
            const action = stopSubmit("addChild", { avatar: message });

            dispatch(action);
            dispatch(actions.setChildLogin(false));
          }

          if (res.status === 401) {
            const message = get(
              res,
              "error.message",
              "Не авторизований користувач не може зареєструвати дитину"
            );
            const action = stopSubmit("addChild", { avatar: message });

            dispatch(action);
            dispatch(actions.setChildLogin(false));
            setTimeout(() => {
              history.push("/auth/login");
            }, 1500);
          }
        })
        .catch((error: unknown) => {
          console.log(error);
          dispatch(actions.setChildLogin(false));
        });
    };

/**
 * POST method. Save lesson's child progress when child completed lesson in not authorized state
 *
 * @param childId {number} - Id of child
 * @param params {Object} - Object with id of lesson what completed.
 * @returns {function(*): Promise<unknown>}
 */
export const saveLessonsProgress =
  (childId: number, params: ISaveProgress) =>
    (dispatch: Dispatch<ActionsType>) => {
      return saveProgressRequest(childId, params)
        .then((res: any) => {
          if (res.status === 200) {
            dispatch(actions.setActiveChild(res.data));
            localStorage.removeItem(completed_level);
            localStorage.removeItem(lessons_saving_progress);
          }
        })
        .catch((error: any) => {
          console.log(error);
        });
    };


export const getCertificate = (childId: number) =>
  (dispatch: Dispatch<ActionsType>) => {
    return getCertificateRequest(childId)
  }
