import { Authorization } from "../../../store/modules/auth/types";

export const getApiPath = (auth: Authorization | null) => {
  const coursesId = localStorage.getItem("courses_id");

  if (auth === "authorized") {
    return `levels/course/${coursesId}/child/${localStorage.getItem(
      "active_child_id"
    )}`;
  }
  if (auth === "notAuthorized") {
    return `levels/course/${coursesId}`;
  }
  return null;
};
