import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import LevelsBar from "./components/LevelsBar";
import LevelList from "./components/LevelList";
import gameZoneSelector from "../../../store/modules/studyZone/selector";
import { getLevels, actions } from "../../../store/modules/studyZone/actions";
import LoadingModal from "../../../components/modals/LoadingModal";
import authSelector from "../../../store/modules/auth/selector";
import ChildLoader from "../../../assets/icons/loaders/preloader_2_for_CSS-1.svg";
import "./levels.scss";
import { AppStateType } from "../../../store/rootReducer";
import { getApiPath } from "./helpers";
import { ILevels, ISetLoading } from "../../../store/modules/studyZone/types";
import { Authorization } from "../../../store/modules/auth/types";
import { History } from "history";
import { Operand } from "../../../types";
import useMediaPath from "../../../hooks/useMediaPath";
import { MediaPathMode } from '../../../constants/enums';

interface IMapState {
	levels: ILevels | null;
	auth: Authorization | null;
}

interface IMapDispatch {
	getLevels: (
		path: string,
		currentPage: number,
		setLocalLoading: ISetLoading,
		history: History
	) => void;
	clearLevels: () => void;
}

type Props = IMapState & IMapDispatch;

/**
 *
 * @param getLevels{function} - Action. Get List of levels from api
 * @param clearLevels{function} - Action. Remove levels from redux
 * @param levels{Object} - Redux value. List of levels with pagination params from api.
 * @param auth{'authorized' || 'notAuthorized'} - Redux value. Show auth user or no
 * @returns {JSX.Element}
 * @constructor
 */

const Levels: React.FC<Props> = ({ getLevels, levels, auth, clearLevels }) => {
	const path = useMediaPath(MediaPathMode.LessonLevel);
	const history = useHistory();
	const [localLoading, setLocalLoading] = useState<boolean>(false);
	const [currentPage, setCurrentPage] = useState(
		+localStorage.getItem("current_page")!
	);


	useEffect(() => {
		if (!localStorage.getItem("current_page")) {
			localStorage.setItem("current_page", "1");
			setCurrentPage(1);
		}

		return () => {
			clearLevels();
		};
	}, [clearLevels]);

	useEffect(() => {
		const path = getApiPath(auth);
		if (auth && path) {
			getLevels(path, currentPage, setLocalLoading, history);
		}
	}, [currentPage, auth, getLevels, history]);

	const choseLesson = (id: number) => {
		history.push(`/levels/${id}`);
	};

	const getNewLevel = async (sign: Operand, lastPage: number) => {
		if (sign === "+" && currentPage < lastPage) {
			await localStorage.setItem("current_page", `${+currentPage + 1}`);
			setCurrentPage(+localStorage.getItem("current_page")!);
		}
		if (sign === "-" && currentPage > 1) {
			await localStorage.setItem("current_page", `${+currentPage - 1}`);
			setCurrentPage(+localStorage.getItem("current_page")!);
		}
	};

	const getContent = () => {
		if (localLoading && levels?.meta.current_page !== currentPage) {
			return (
				<div className="flex-center">
					<img src={ChildLoader} alt="loading" className="loader-icon"/>
				</div>
			);
		}
		if (levels) {
			return (
				<LevelList levels={levels.data} choseLesson={choseLesson} auth={auth}/>
			);
		}
	};

	if (!levels) {
		return (
			<div className="levels">
				<LoadingModal isForChild/>
			</div>
		);
	}

	return (
		<div
			className="vertical-screen"
			style={{background: `center/cover no-repeat url(${path}), linear-gradient(63.18deg, #845EC2 0%, #00C9B7 100%)`}}
		>
			<div
					 className="levels"
					 style={{backdropFilter: path?.includes('small') ?  'blur(5px)' : 'none'}}
			>
				<LevelsBar
					position="left"
					links={levels.links}
					currentPage={currentPage}
					getNewLevel={getNewLevel}
				/>
				{getContent()}
				<LevelsBar
					position="right"
					links={levels.links}
					currentPage={currentPage}
					getNewLevel={getNewLevel}
				/>
			</div>
		</div>
	);
};

const mapStateToProps = (state: AppStateType): IMapState => ({
	levels: gameZoneSelector.getLevels(state),
	auth: authSelector.getAuth(state),
});

const mapDispatchToProps: IMapDispatch = {
	getLevels,
	clearLevels: actions.clearLevels,
};

export default connect(mapStateToProps, mapDispatchToProps)(Levels);
