import React, { useEffect, useState } from "react";
import "./gameMenu.scss";

interface IProps {
  starContainer: JSX.Element;
  navContainer: JSX.Element;
  isMenuOpen: boolean;
}

/**
 *  Component when widget starts
 *
 * @param starContainer{JSX} - HTML elements with Star Icon
 * @param navContainer{JSX} - HTML elements with buttons (YouTube || home || gameList... )
 * @param isMenuOpen{boolean} - shows need open menu or not.
 * @returns {JSX.Element}
 * @constructor
 */
const GameMenu: React.FC<IProps> = ({
  starContainer,
  navContainer,
  isMenuOpen,
}) => {
  const [isShow, setIsShow] = useState(false); // create delay for animation execution

  useEffect(() => {
    if (!isMenuOpen) {
      setTimeout(() => setIsShow(isMenuOpen), 500);
    }

    if (isMenuOpen) {
      setIsShow(isMenuOpen);
    }
  }, [isMenuOpen]);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
  };

  return (
    <>
      {isShow && <div className="gameMenu" onClick={handleClick} />}
      {isShow && starContainer}
      {isShow && navContainer}
    </>
  );
};

export default GameMenu;
