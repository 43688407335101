import React, { useState } from "react";
import { uploadReadySounds } from "../../helpers";
import ClickHandlerPage from "../ClickHandlerPage";
import GameModule from "../GameModule";
import { IReadySounds } from "../../store/modules/media/types";

interface Props {
  setPartsCount: (prevState: any) => any;
  configData: any;
  isAnimation: boolean;
  audio: IReadySounds | null;
  isPause: boolean;
}

const GameModuleAudioWrapper: React.FC<Props> = ({
  setPartsCount,
  configData,
  isAnimation,
  audio,
  isPause,
}) => {
  const [isShouldReload, setIsShouldReload] = useState(false);

  const clickHandler = () => {
    // @ts-ignore
    if (audio) {
      uploadReadySounds(audio);
    }
    setIsShouldReload(false);
  };

  return (
    <>
      {isShouldReload && (
        <ClickHandlerPage animationStartButton={clickHandler} />
      )}

      <GameModule
        setPartsCount={setPartsCount}
        configData={configData}
        isAnimation={isAnimation}
        setIsShouldReload={setIsShouldReload}
        audio={audio}
        isPause={isPause}
      />
    </>
  );
};

export default GameModuleAudioWrapper;
