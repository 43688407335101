import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import profileSelector from "../../store/modules/profile/selector";
import childrenSelector from "../../store/modules/children/selector";
import { getProfile } from "../../store/modules/profile/actions";
import { actions, saveLessonsProgress } from "../../store/modules/children/actions";
import { AppStateType } from "../../store/rootReducer";
import { IProfile } from "../../store/modules/profile/types";
import { History } from "history";
import { IChild, ISaveProgress } from "../../store/modules/children/types";
import { getCourseSub } from "../../store/modules/payment/actions";
import paymentSelector from "../../store/modules/payment/selector";
import { ISubCourseInfo } from "../../store/modules/payment/types";
import {  getProgressData } from '../../helpers';
import { UnregisterSaveProgressMode } from '../../constants/enums';
import { saveUnregisterArcadesProgress } from '../../store/modules/gameZone/actions';

interface IMapState {
	profile: IProfile | null;
	activeChildId: number | null;
	activeChild: IChild | null;
}

interface IMapDispatch {
	getProfile: (history: History, setIsDataUpload: (prev: any) => any) => void;
	saveLessonsProgress: (childId: number, params: ISaveProgress) => void;
	saveUnregisterArcadesProgress: (childId: number, params: ISaveProgress) => void;
	getCourseSub: (childId: number, history: History) => void;
	setActiveChildId: (childId: number) => void;
}

type Props = IMapState & IMapDispatch;

/**
 *
 * @param profile {Object} - Redux value. Object with data of profile
 * @param getProfile {Function} - Action. Action get data of profile from api
 * @param saveLessonsProgress {Function} - Action. Action save lessons child progress from localStorage in api,
 * @param saveUnregisterArcadesProgress {Function} - Action. Action save arcades child progress from localStorage in api,
 * @param children {any} - JSX elements like react children
 * @param activeChildId {null} - Redux value. Active child id
 * @param getCourseSub {Function} - Action. Get information about Course
 * @param setActiveChildId {Function} - Action. Set active child Id
 * @returns {JSX.Element}
 * @constructor
 */

const RouteLogic: React.FC<Props> = ({
																			 profile,
																			 getProfile,
																			 saveLessonsProgress,
																			 children,
																			 activeChildId,
																			 setActiveChildId,
																			 saveUnregisterArcadesProgress
																		 }) => {
	const [isDataUpload, setIsDataUpload] = useState(false);

	const history = useHistory();

	useEffect(() => {
		const idOfChild =
			activeChildId || +localStorage.getItem("active_child_id")!;

		if (idOfChild) {
			setActiveChildId(idOfChild);
		}

		getProfile(history, setIsDataUpload);
	}, []);

	useEffect(() => {
		// TODO Now we not develop logic for courses but put in architecture
		if (!localStorage.getItem("courses_id")) {
			localStorage.setItem("courses_id", "1");
		}
		if (profile) {
			if (!profile.email) {
				history.push("/settings/add-email");
			}

			if (profile.email && !profile.email_verified_at) {
				history.push({
					pathname: "/auth/verification",
					state: {
						from: "/auth/login",
					},
				});
			}

			if (
				!profile.children.length &&
				profile.email &&
				profile.email_verified_at
			) {
				history.push("/settings/add-child");
			}

			if (
				profile.children.length &&
				profile.email &&
				profile.email_verified_at &&
				!profile.key_word
			) {
				history.push("/settings/add-keyword");
			}

			if (!localStorage.getItem("active_child_id") && profile.children.length) {
				localStorage.setItem("active_child_id", String(profile.children[0].id));
				setActiveChildId(profile.children[0].id);

				const lessonsProgressData = getProgressData(UnregisterSaveProgressMode.Lesson);
				const arcadesProgressData = getProgressData(UnregisterSaveProgressMode.Game);

				if (lessonsProgressData.length) {
					const params = {
						lessons: lessonsProgressData,
					};
					saveLessonsProgress(+localStorage.getItem("active_child_id")!, params);
				}
				if (arcadesProgressData.length) {
					const params = {
						levels: arcadesProgressData
					}
					saveUnregisterArcadesProgress(+localStorage.getItem("active_child_id")!, params)
				}
			}
			if (
				localStorage.getItem("active_child_id") &&
				profile.children.length &&
				!profile.children.find(
					(item) => item.id === +localStorage.getItem("active_child_id")!
				)
			) {
				localStorage.removeItem("active_child_id");
			}

			if (
				profile.children.length !== 0 &&
				profile.email &&
				profile.email_verified_at &&
				profile.key_word
			) {
				setIsDataUpload(true);
			}
		}
	}, [profile, history]);

	if (isDataUpload && !children) {
		history.push("/home");
	}

	return <>{children}</>;
};

const mapStateToProps = (state: AppStateType): IMapState => ({
	profile: profileSelector.getProfile(state),
	activeChildId: childrenSelector.getActiveChildId(state),
	activeChild: childrenSelector.getActiveChild(state),
});

const mapDispatchToProps: IMapDispatch = {
	getProfile,
	saveLessonsProgress,
	saveUnregisterArcadesProgress,
	getCourseSub,
	setActiveChildId: actions.setActiveChildId,
};

export default connect(mapStateToProps, mapDispatchToProps)(RouteLogic);
