import React, { FC, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import cn from 'classnames'
import { History } from 'history';
import Lock, { ILockHandle } from '../../../../../components/Lock';
import { LockMode } from '../../../../../constants/enums';

interface IGameCard {
	categoryId: string
	gameId: number
	image: string
	name: string
	isAvailable: boolean
}

const GameCard: FC<IGameCard> = ({ categoryId, gameId, image, name, isAvailable }) => {
	const history: History = useHistory();
	const lockRef = useRef<ILockHandle>(null);

	const onClickHandler = () => {
		if (isAvailable) {
			history.push(`/game-categories/${categoryId}/game/${gameId}`)
		}
		if (!isAvailable && lockRef.current) {
			lockRef.current.onStartAnimation()
		}
	}

	return (
		<div
			className="game-list__element"
			onClick={onClickHandler}
			onKeyDown={onClickHandler}
		>
			{!isAvailable && (
				<div className="game-list__lock">
					<Lock ref={lockRef} mode={LockMode.Filled}/>
				</div>
			)}
			<img className={cn("game-list__element-img", { "disabled": !isAvailable })} src={image} alt="game"/>
			<span className="game-list__element-text">{name}</span>
		</div>
	);
};

export default GameCard;
