export const sliderSettings = {
  className: "center",
  infinite: false,
  slidesToShow: 1,
  arrows: false,
  centerMode: true,
  variableWidth: true,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: false,
        dots: false,
        centerMode: true,
      },
    },
  ],
};
