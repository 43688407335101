import request from "../../request";
import {
  IGetArcadeLevelsParams,
  IGetCategoriesParams,
  IGetGameListParams,
  ISaveArcadesProgressParams,
  ISetLevelCompletedParams,
} from "./types";
import { toCamelCase } from "../../../helpers";
import { ISaveProgress } from '../children/types';

export const getCategoriesRequest = ({ childId }: IGetCategoriesParams) => {
  return request.get(`arcades-categories/${childId}`);
};

export const getGameListRequest = async (
  activeChildId: number,
  categoryId: number
) => {
  const result = await request.get(
    `arcades-categories/${activeChildId}/${categoryId}/arcades`
  );
  return { ...result, data: result.data.map((el: unknown) => toCamelCase(el)) };
};

export const getGameRequest = ({ childId, levelId }: IGetGameListParams) => {
  return request.get(`arcade-levels/parts/${levelId}`, {
    childId,
  });
};

export const getArcadeLevelsRequest = ({
  path,
  activeChildId,
}: IGetArcadeLevelsParams) => request.get(path, { childId: activeChildId });

export const saveArcadesProgressRequest = ({
  childId,
  partId,
}: ISaveArcadesProgressParams) => request.keepAlivePost(`arcades/${childId}/save-progress`, { partId });

export const setLevelCompletedRequest = ({
  childId,
  levelId
}: Partial<ISetLevelCompletedParams>) => request.post(`arcade-levels/${childId}/${levelId}/complete-level`)

export const saveUnregisterArcadesProgressRequest = (
  childId: number,
  params: ISaveProgress
) => request.post(`arcade-levels/${childId}/save-progress`, params)
