import React, { MutableRefObject, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { actions, getGameData, saveArcadeProgress, setLevelCompleted, } from "../../../store/modules/gameZone/actions";
import gameZoneSelector from "../../../store/modules/gameZone/selector";
import childLoader from "../../../assets/icons/loaders/preloader_2_for_CSS-1.svg";
import CornerButton from "../../../components/buttons/CornerButton";
import "./game.scss";
import childrenSelector from "../../../store/modules/children/selector";
import GameCornerMenu from "./components/GameCornerMenu";
import ClickHandlerPage from "../../../components/ClickHandlerPage";
import {
  active_child_id,
  animation_item,
  animationStart,
  arcades_completed_levels, checkAnimationId,
  endOfAnimatedAudio,
  game_game_id,
  removeAudio,
  removeLocalProgressById,
  saveUnregisterProgress,
  shouldShowAnimation,
  uploadReadySounds,
  uploadSounds
} from "../../../helpers";
import { UnregisterSaveProgressMode } from "../../../constants/enums";
import authSelector from "../../../store/modules/auth/selector";
import useDidMountEffect from "../../../hooks/useDidMountEffect";
import useHistoryWithReplace from "../../../hooks/useHistoryWithReplace";
import { setSavedPartId } from "../../../helpers/saveProgress";
import { actions as mediaActions, getSounds } from '../../../store/modules/media/actions';
import mediaSelector from '../../../store/modules/media/selector';
import GameModuleAudioWrapper from '../../../components/GameModuleAudioWrapper';
import { IReadySounds } from '../../../store/modules/media/types';

const audioBoy = require("../../../assets/audio/game_boy.mp3");

interface IParams {
  categoryId: string;
  gameId: string;
  levelId: string;
}

/**
 *  Component where games start in the game library
 *
 * @returns {JSX.Element}
 * @constructor
 */
const Game = () => {
  const [partsCount, setPartsCount] = useState(0);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isAnimation, setIsAnimation] = useState(
    shouldShowAnimation(game_game_id)
  );

  const childId = useSelector(childrenSelector.getActiveChildId);
  const { gameData, loading } = useSelector(gameZoneSelector.getGameStore);
  const auth = useSelector(authSelector.getAuth);
  const { sounds }  = useSelector(mediaSelector.getMediaStore);

  const [isTouchScreen, setIsTouchScreen] = useState(false)
  const partCountId = useRef<number>(0);
  const isCompleted = useRef<boolean>(false);

  // const history: History = useHistory();
  const [history, replaceStore, state] = useHistoryWithReplace();
  const { categoryId, gameId, levelId } = useParams<IParams>();
  const dispatch = useDispatch();

  // Should be here because firs animation screen.
  let audio: MutableRefObject<HTMLAudioElement | null> = useRef(new Audio(audioBoy));
  const [uploadedSounds, setUploadedSounds] = useState<IReadySounds | null>(null);

  useEffect(() => {
    document.addEventListener("visibilitychange", visibilityChange);
    dispatch(getSounds());

    if (checkAnimationId(game_game_id)) {
      animationStartButton();
    }
    return () => {
      saveProgress();
      dispatch(mediaActions.setSounds(null));
      if (audio.current) removeAudio(audio.current);
      endOfAnimatedAudio(audio.current, setIsAnimation, game_game_id);
      document.removeEventListener("visibilitychange", visibilityChange);
    };
  }, []);

  useEffect(() => {
    // if (categoryId && gameId && activeChildId) {
    //   getGameData(activeChildId, +categoryId, +gameId, history);
    // }

    if (categoryId && gameId) {
      dispatch(getGameData({ childId, levelId }, { history }));
    }

    return () => {
      dispatch(actions.setGameData(null));
    };
  }, [childId]);

  useEffect(() => {
    if (sounds) {
      setUploadedSounds(uploadSounds(sounds));
    }
  }, [sounds]);

  // Set lesson's part id to ref. For sending to BE if user go out from the lesson.
  useEffect(() => {
    if (gameData && gameData.parts.length > partsCount) {
      partCountId.current = gameData.parts[partsCount].id;
    }
  }, [partsCount, gameData]);

  useDidMountEffect(() => {
    setSavedPartId(
      {
        id: levelId,
        auth,
        mode: UnregisterSaveProgressMode.Game,
        state,
        data: gameData,
      },
      { setPartsCount, replaceStore }
    );
  }, [gameData]);

  const visibilityChange = () => {
    if (document.hidden) {
      saveProgress();
    }
  };

  const saveProgress = () => {
    const childId = localStorage.getItem(active_child_id);

    if (childId && !isCompleted.current) {
      const partId = partCountId.current;
      if (childId && partId) {
        dispatch(saveArcadeProgress({ childId, partId: partCountId.current }));
      }
    }
    if (!childId && !isCompleted.current) {
      saveUnregisterProgress({
        id: levelId,
        partCountId: partCountId.current,
        mode: UnregisterSaveProgressMode.Game,
      });
    }
  };

  const animationStartButton = () => {
      audio.current = new Audio(audioBoy);
      setIsTouchScreen(false);
      animationStart(audio.current, setIsAnimation, game_game_id, () => {setIsTouchScreen(true)});
    if (uploadedSounds) {
      uploadReadySounds(uploadedSounds);
    }
  };

  if (loading || !gameData) {
    return (
      <div className="vertical-screen">
        <div className="game-list flex-center">
          <img src={childLoader} alt="loading" className="loader-icon" />
        </div>
      </div>
    );
  }

  if (gameData.parts.length === partsCount) {
    isCompleted.current = true;
    if (auth === "authorized" && childId) {
      dispatch(
        setLevelCompleted(
          {
            childId,
            levelId,
            gameId,
            isCompleted: gameData.IsCompleted,
            categoryId,
          },
          { history }
        )
      );
    }
    if (auth === "notAuthorized") {
      const completedLevels = localStorage.getItem(arcades_completed_levels);

      removeLocalProgressById(UnregisterSaveProgressMode.Game, levelId);

      if (completedLevels) {
        const isCompleted = completedLevels
          .split(",")
          .some((el) => el === levelId);

        if (!isCompleted) {
          localStorage.setItem(
            arcades_completed_levels,
            `${completedLevels},${levelId}`
          );
          sessionStorage.setItem(animation_item, levelId);
        }
      }

      // First completed Level BL for unregister User
      if (!completedLevels) {
        localStorage.setItem(arcades_completed_levels, levelId);
        sessionStorage.setItem(animation_item, levelId);
      }

      history.push(`/game-categories/${categoryId}/game/${gameId}`);
    }

    return null;
  }

  if (state?.partId) {
    return null
  }

  if (isTouchScreen) {
    return <ClickHandlerPage animationStartButton={animationStartButton} />;
  }

  return (
    <div className="game">
      <div className="vertical-screen">
        <div className="testStyle" ></div>
        <GameCornerMenu
          isMenuOpen={isMenuOpen}
          categoryId={categoryId!}
          isAnimation={isAnimation}
        />

        <CornerButton
          isMenuOpen={isMenuOpen}
          setIsMenuOpen={setIsMenuOpen}
          isAnimation={isAnimation}
        />

        <GameModuleAudioWrapper
          setPartsCount={setPartsCount}
          configData={gameData.parts[partsCount]?.content}
          isAnimation={isAnimation}
          audio={uploadedSounds}
          isPause={isMenuOpen}
        />
      </div>
    </div>
  );
};

export default Game;
