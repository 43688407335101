import React, { ReactElement } from "react";
import { ReactComponent as GoogleIcon } from "../../../assets/icons/google.svg";
import { ReactComponent as FacebookIcon } from "../../../assets/icons/facebook-f.svg";
import cn from 'classnames';
import "./mainButton.scss";

interface IProps {
	text: string;
	className: string;
	disabled?: boolean;
	type?: "button" | "submit" | "reset" | undefined;
	onClick?: () => any;
	readOnly?: boolean;
	leftIcon?: () => ReactElement,
}

/**
 * Frame for auth buttons
 *
 * @param {string}text - Text in button
 * @param {string}className - Second class for set the color, ets.
 * @param props{any} - Additional props that may be needed. Not required
 * @param leftIcon{Function} - Function Return left Icon
 * @returns {JSX.Element}
 * @constructor
 */
const MainButton: React.FC<IProps> = ({ text, className, leftIcon, ...props }) => {
	return (
		<button type="button" className={cn("login__buttons",  className)} {...props}>
			{leftIcon && (
				<span className="login__buttons_icon-left">
					{leftIcon()}
				</span>
			)}
			{text === "Google" ? <GoogleIcon className="login__button-img"/> : null}
			{text === "Facebook" ? (
				<FacebookIcon className="login__button-img"/>
			) : null}

			{text}
		</button>
	);
};

export default MainButton;
