import React, { lazy } from "react";
import { Redirect, Route } from "react-router-dom";
import { connect } from "react-redux";
import authSelector from "../store/modules/auth/selector";
import { AppStateType } from "../store/rootReducer";
import { Authorization } from "../store/modules/auth/types";
const AuthRouts = lazy(
  () => import(/* webpackChunkName: "AuthRouts" */ "./routes/authRouts")
);

interface IOwnProperties {
  path: string;
}

interface IMapState {
  auth: Authorization | null;
}

type Props = IOwnProperties & IMapState;

const AuthRouter: React.FC<Props> = ({ auth, ...rest }) => {
  if (auth) {
    return (
      <>
        <Route
          {...rest}
          render={(props) => {
              return (
                // @ts-ignore
                <AuthRouts {...props} />
              );
          }}
        />
      </>
    );
  }
  return null;
};

const mapStateToProps = (state: AppStateType): IMapState => ({
  auth: authSelector.getAuth(state),
});

export default connect(mapStateToProps)(AuthRouter);
