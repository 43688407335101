import React, { memo, useRef } from "react";
import "./backgroundVideo.scss";

import AutumnImage from "../../assets/image/home/autumn.jpg";
import SpringImage from "../../assets/image/home/spring.jpg";
import SummerImage from "../../assets/image/home/summer.jpg";
import WinterImage from "../../assets/image/home/winter.jpg";
import { MediaPathMode } from '../../constants/enums';
import useMediaPath from '../../hooks/useMediaPath';

const BackgroundVideo = () => {
	const path = useMediaPath(MediaPathMode.HomeVideo);
	const image = useRef<string>();
	const data = new Date().getMonth();
	const videoRef = useRef()

	if (data === 11 || data === 0 || data === 1) {
		image.current = WinterImage;
	}

	if (data >= 2 && data <= 4) {
		image.current = SpringImage;
	}

	if (data >= 5 && data <= 7) {
		image.current = SummerImage;
	}

	if (data >= 8 && data <= 10) {
		image.current = AutumnImage;
	}

	return (
		<div className="video-container"
			// style={{background: `center /contain no-repeat url(${image.current})`}}
		>
			<video
				style={{ background: `center/contain no-repeat url(${image.current})` }}
				// @ts-ignore
				ref={videoRef}
				id="back-video"
				poster={image.current}
				autoPlay
				loop
				muted
				playsInline
				className="video-tag"
			>
				<source src={`${path}#t=0.001`} type="video/mp4" className="video-source"/>
			</video>
		</div>
	);
};

export default memo(BackgroundVideo);
