import React, { useState } from "react";
import { ReactComponent as OpenEye } from "../../../assets/icons/open_eye.svg";
import { ReactComponent as CloseEye } from "../../../assets/icons/close_eye.svg";
import { WrappedFieldProps } from "redux-form";
import "./authInput.scss";

interface IOwnProps {
  type: string;
  submitErrorValue: any; // submit error from Redux Form
}

type Props = WrappedFieldProps & IOwnProps;

/**
 * Main input in the site.
 *
 * @param input - Props from redux-form. Needs for correct rendering in Field(redux-form)
 * @param submitErrorValue{object} - Object with fields with error when submit failed
 * @param {boolean}touched - Show touched input or no.
 * @param {string}error - Message that is displayed in case of incorrect validation
 * @param {string}type - Type of input. Can be email/text/password
 * @param props - Addition props that may be needed. Not required
 * @returns {JSX.Element}
 * @constructor
 */
const AuthInput: React.FC<Props> = ({
  input,
  meta: { touched, error },
  type,
  submitErrorValue,
  ...props
}) => {
  const [hidePassword, setHidePassword] = useState(type === "password");
  const [isOpenEye, setIsOpenEye] = useState(true);

  // Show Eye if type of input 'password'. When you click change the type to 'text' or 'password'
  const getEye = () => {
    if (type === "password") {
      return (
        <div
          className="input-eye__container"
          onClick={() => {
            setIsOpenEye((prevState) => !prevState);
            setHidePassword((prevState) => !prevState);
          }}
          onKeyDown={() => setHidePassword((prevState) => !prevState)}
        >
          {isOpenEye && <OpenEye />}
          {!isOpenEye && <CloseEye />}
        </div>
      );
    }
    return null;
  };

  const getType = () => {
    if (hidePassword && type) {
      return type;
    }
    return "text";
  };

  // Responsive class name. Change when input touched and not pass validation
  const clazz =
    (touched && error) || submitErrorValue
      ? "auth__input auth__input-warning"
      : "auth__input auth__input-normal";

  return (
    <div className="auth-input__container">
      <input
        className={clazz}
        {...input}
        {...props}
        type={getType()}
        autoComplete="off"
      />
      {/* Show error */}
      {touched && error && <span className="validate-warning">{error}</span>}

      {getEye()}
    </div>
  );
};

export default AuthInput;
