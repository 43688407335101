import React from "react";
import classNames from "classnames";
import Modal from "react-modal";
import { useTranslation } from "react-i18next";
import "./areSureModal.scss";
import "../modals.scss";

interface Props {
  successModal?: (e: React.MouseEvent<HTMLElement>) => void;
  closeModal: () => void;
  text: string;
  forChild: boolean;
  horizontal?: boolean;
  confirmElement?: any;
}

/**
 * Modal with two buttons Yes and No.
 *
 * @param successModal{function} - Function when you click yes
 * @param closeModal{function} - Function when you click no
 * @param text{string} - Text in Modal
 * @param forChild{boolean} - if true highlights the green button no if false highlights button yes
 * @param horizontal{boolean} - Indicates whether the button will only be displayed horizontally
 * @param confirmElement{HTMLElement} - HTML button
 * @returns {JSX.Element}
 * @constructor
 */
const AreSureModal: React.FC<Props> = ({
  successModal,
  closeModal,
  text,
  forChild,
  horizontal = false,
  confirmElement,
}) => {
  const { t } = useTranslation();

  const getConfirm = () => {
    if (confirmElement) {
      return confirmElement;
    }

    return (
      <div
        className={classNames(" log-out-button flex-center", {
          "log-out__confirm": forChild,
          "log-out__refusal": !forChild,
        })}
        onClick={successModal}
      >
        {t("common.AreSureModal.confirm")}
      </div>
    );
  };

  return (
    <Modal
      isOpen
      ariaHideApp={false}
      contentLabel="Example Modal"
      className={classNames("hint-modal flex-center", {
        "hint-modal__horizontal": horizontal,
      })}
      overlayClassName="overlay overlay-dark"
    >
      <div className="log-out">
        <div className="log-out-text margin-bottom_30 text-center">{text} </div>
        <div className="log-out__button-container">
          {getConfirm()}

          <div
            className={classNames(" log-out-button flex-center", {
              "log-out__confirm": !forChild,
              "log-out__refusal": forChild,
            })}
            onClick={closeModal}
            onKeyDown={closeModal}
          >
            {t("common.AreSureModal.refusal")}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default AreSureModal;
