import React from "react";
import { ReactComponent as StarBoy } from "../../../../../assets/icons/boy_star.svg";
import classNames from "classnames";
import RoundButton from "../../../../../components/buttons/RoundButton";
import home from "../../../../../assets/icons/home.svg";
import youTube from "../../../../../assets/icons/youTube_icon.svg";
import cubes from "../../../../../assets/icons/home/cubes.svg";
import GameMenu from "../../../../../components/GameMenu";
import { useHistory } from "react-router-dom";
import { animationHandlerClick } from "../../../../../helpers";

interface IProps {
  isAnimation: boolean;
  isMenuOpen: boolean;
  videoHandler: () => void;
  levelId: string;
}

const LessonCornerMenu: React.FC<IProps> = ({
  isAnimation,
  isMenuOpen,
  videoHandler,
  levelId,
}) => {
  const history = useHistory();

  const starContainer = () => (
    <div className="game__starContainer">
      <StarBoy
        className={classNames("lesson-star game__star-showAnimated", {
          "game__star-hideAnimated": !isMenuOpen && !isAnimation,
          "lesson-game__star-boy_animation": isAnimation,
        })}
      />
    </div>
  );

  const navContainer = () => (
    <div className="lesson__buttonContainer">
      <RoundButton
        image={home}
        onClick={() =>
          animationHandlerClick(isAnimation, () => history.push("/home"))
        }
        alt="next arrow"
        className={classNames(
          "lessons-nav_circle game__button-showAnimatedTop",
          {
            "game__button-hideAnimatedTop": !isMenuOpen && !isAnimation,
            "lesson__button-homeAnimation": isAnimation,
          }
        )}
        imageClassName="lessons-nav-image"
      />
      <RoundButton
        image={youTube}
        onClick={() => animationHandlerClick(isAnimation, videoHandler)}
        alt="youTube"
        className={classNames(
          "lessons-nav_circle game__button-showAnimatedTop",
          {
            "game__button-hideAnimatedTop": !isMenuOpen && !isAnimation,
            "lesson__button-youTubeAnimation": isAnimation,
          }
        )}
        imageClassName="lessons-nav-image"
      />
      <RoundButton
        image={cubes}
        onClick={() =>
          animationHandlerClick(isAnimation, () =>
            history.push(`/levels/${levelId}`)
          )
        }
        alt="next arrow"
        className={classNames(
          "lessons-nav_circle game__button-showAnimatedBottom",
          {
            "game__button-hideAnimatedBottom": !isMenuOpen && !isAnimation,
            "lesson__button-cubesAnimation": isAnimation,
          }
        )}
        imageClassName="lessons-nav-image"
      />
    </div>
  );

  return (
    <GameMenu
      isMenuOpen={isMenuOpen || isAnimation}
      starContainer={starContainer()}
      navContainer={navContainer()}
    />
  );
};

export default LessonCornerMenu;
