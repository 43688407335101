import request, { authorizeRequest, getBaseOptions, getRequestUrl } from "../../request";
import { IChildRequest, IChildrenHeader, ISaveProgress } from "./types";
import env from '@beam-australia/react-env';

export const addChildRequest = (
  params: IChildRequest,
  option: IChildrenHeader
) => {
  return request.postData("children", params, option);
};

export const upgradeChildRequest = (
  childId: number,
  params: IChildRequest,
  option: IChildrenHeader
) => {
  return request.postData(`children/${childId}?_method=PUT`, params, option);
};

export const getChildrenRequest = () => {
  return request.get("children");
};

export const getChildDetailsRequest = (childId: number) => {
  return request.get(`children/${childId}`);
};

export const removeChildRequest = (childId: number) => {
  return request.delete(`children/${childId}`);
};

export const saveProgressRequest = (childId: number, params: ISaveProgress) => {
  return request.post(`children/${childId}/progress`, params);
};

export const getCertificateRequest =  async (childId: number) => {
  let options: any = getBaseOptions('GET', {});
  const auth = await authorizeRequest(options);
  options = Object.assign(options, auth);

  return  fetch(getRequestUrl(env("BACKEND_API_BASE_URL"), `children/${childId}/get-certificate`), options).then(response => {
    response.blob().then(blob => {
      const fileURL = window.URL.createObjectURL(blob);
      let alink = document.createElement('a');
      alink.href = fileURL;
      alink.download = 'SamplePDF.pdf';
      alink.click();
    })
  })
};
