import React from "react";
import { IChild } from "../store/modules/children/types";
import { camelCase, mapKeys, rearg } from "lodash";
import MainButton from "../components/buttons/MainButton";
import { ILocalSavingProgress } from '../store/modules/studyZone/types';
import { UnregisterSaveProgressMode } from '../constants/enums';
import { IReadySounds, ISoundItem, ISounds } from '../store/modules/media/types';

// -------------------  CONSTANTS --------------------

export const visibilityStateStart = 'visibilityStateStart';
export const active_child_id = 'active_child_id';
export const game_game_id = 'game_game';
export const lesson_game_id = 'lesson_game';
export const lesson_lesson_id = 'lesson_lesson';
export const completed_level = 'completed_level';
export const lessons_saving_progress = 'lessons_saving_progress';
export const arcade_saving_progress = 'arcade_saving_progress';
export const animation_item = 'animation_item';
export const animation_cub = 'animation_cub';
export const arcades_completed_levels = 'arcades_completed_levels';


// -------------------  !CONSTANTS --------------------

export interface IRequestProgress {
	lessonId?: number
	levelId?: number
	partId: number | null
	isCompleted: boolean
}

// 'lesson_lesson' -  mainScreen of lesson
// 'lesson_game_id' - game of lesson
// 'game_game_id' - Game animation in game_zone
type animationIdType = typeof lesson_lesson_id | typeof lesson_game_id | typeof game_game_id;

export const getHeightDevice = () => {
	return Math.max(
		window.innerHeight * 0.01,
		document.documentElement.clientHeight * 0.01
	);
};

export const getWidthDevice = () => {
	return Math.max(
		window.innerWidth * 0.01,
		document.documentElement.clientWidth * 0.01
	);
};

/**
 * Shows its portrait orientation or not. If Portrait return true
 *
 * @param orientation {string} - Value with type of orientation from eventListener 'orientationchange' - event.target.screen.orientation.type
 * @returns {boolean}
 */
export const getIsPortrait = (orientation: string) => {
	return (
		orientation === "portrait-primary" || orientation === "portrait-secondary"
	);
};

export const getCompleteLesson = (lessonsList: any) => {
	let completedCount = 0;

	// @ts-ignore boolean
	lessonsList.forEach(({ completed }) => {
		if (completed) {
			completedCount += 1;
		}
	});
	return completedCount;
};

export const getLocalCompletedLength = () => {
	const completedLevel = localStorage.getItem("completed_level")
	if (!completedLevel) {
		return 0;
	}
	return completedLevel.split(",").length;
};

export const getImageContent = (item: IChild) => {
	if (item.avatar) {
		return <img src={item.avatar} alt="" className="add-child__image"/>;
	}
	return item.name.trim().split("")[0].toUpperCase();
};

/// it's will be deleted in next merge
export const changeActiveSubPlanBlock = (elements: any, key: number) => {
	return elements.map((element: any) => ({
		...element,
		active: element.key === key,
	}));
};

export const getOrientation = () => {
	if (window.innerHeight > window.innerWidth) {
		return "portrait-primary";
	}
	return "landscape-primary";
};

// Object to camel Case
export const toCamelCase = (object: any) =>
	mapKeys(object, rearg(camelCase, 1));

// Text for payBlock
export const getPayText = (
	t: any,
	iterationCount: number,
	isActiveCourse: boolean
) => {
	if (iterationCount === 1 && !isActiveCourse) {
		return t("SubPlan.ones_pay");
	}
	if (iterationCount === 1 && isActiveCourse) {
		return `${iterationCount.toFixed()} ${t("SubPlan.one_pay")}`;
	}
	if (iterationCount > 1 && iterationCount < 5) {
		return `${iterationCount.toFixed()} ${t("SubPlan.two_four_pay")}`;
	}
	if (iterationCount >= 5) {
		return `${iterationCount.toFixed()} ${t("SubPlan.more_five_pay")}`;
	}
};

// Button Form (liqpay)
export const getSubForm = (data: string, signature: string) => {
	return (
		<form
			method="POST"
			action="https://www.liqpay.ua/api/3/checkout"
			acceptCharset="utf-8"
		>
			<input type="hidden" name="data" value={data}/>
			<input type="hidden" name="signature" value={signature}/>
			<MainButton
				text="Так"
				className="login__button-green log-out-button flex-center"
				onClick={() => {
				}}
				type="submit"
			/>
		</form>
	);
};

/**
 * Set completed animation in localStorage
 *
 * @param animationId{string} -  Animation Id
 */
export const setDoneAnimationId = (animationId: animationIdType) => {
	if (localStorage.getItem("completed_animation")) {
		const completedAnimation = localStorage
			.getItem("completed_animation")!
			.split(",");
		const isLessonAnimation = completedAnimation.find(
			(animationName) => animationName === animationId
		);
		if (isLessonAnimation) {
			return;
		}
		completedAnimation.push(animationId);
		const newCompletedAnimation = completedAnimation.join(",");
		localStorage.setItem("completed_animation", newCompletedAnimation);
	} else {
		localStorage.setItem("completed_animation", animationId);
	}
};

/**
 * If animation is false run action
 *
 * @param isAnimation{boolean} - State Value. Show is the animation running.
 * @param middleware{function} - Run function if animation doesn't run.
 */
export const animationHandlerClick = (
	isAnimation: boolean,
	middleware: () => void
) => {
	if (isAnimation) return;
	middleware();
};

export const animationStart = (
	audio: HTMLAudioElement,
	setIsAnimation: (prevState: boolean) => void,
	animationId: animationIdType,
	callBack: () => void
) => {
	if (audio) {
		audio.load();
		setTimeout(() => {
			if (isIOS()) {
				audio.play().then(() => {
					setIsAnimation(true);
				}).catch(() => {
					console.log('callBack')
					callBack()
				});
			}
			if (!isIOS()) {
				audio.play().catch(() => {
					console.log('callBack')
					callBack()
				});
				setIsAnimation(true);
			}
			audio.addEventListener("ended", () =>
				endOfAnimatedAudio(audio, setIsAnimation, animationId)
			);
		}, 100);
	}
};

// remove Audio from memory;
export const endOfAnimatedAudio = (
	audio: HTMLAudioElement | null,
	setIsAnimation: (prevState: boolean) => void,
	animationId: animationIdType
) => {
	console.log('endOfAnimatedAudio')
	setIsAnimation(false);
	if (audio) {
		audio.pause();
		audio.currentTime = 0;
		audio = null;
	}
	setDoneAnimationId(animationId);
};

export const isIOS = () => {
	// @ts-ignore
	return (/iPad|iPhone|iPod/.test(window.navigator.userAgent) && !window.MSStream) || window.navigator.standalone;
}

export const isSafari = () => {
	return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
}

export const isAndroid = () => {
	// @ts-ignore
	return /Android|android/.test(window.navigator.userAgent)
}

export const uploadAudio = (audioCorrect: HTMLAudioElement, audioFail: HTMLAudioElement) => {
	if(audioCorrect) {
		audioCorrect.load();
	}
	if (audioFail) {
		audioFail.load();
	}
}

export const uploadSounds = (sounds: ISounds): IReadySounds => {
	let data: IReadySounds = {};
	 Object.keys(sounds).forEach((key) => {
		 data[key] = {
			 // @ts-ignore
			 count: sounds[key].count,
			 // @ts-ignore
			 items: sounds[key].items.map((item: ISoundItem) => {
				 const audio = new Audio(item.path);
				 audio.load()
				 return audio;
			 })
		 }
	})
	return data;
}

export const uploadReadySounds = (sounds: IReadySounds) => {
	Object.keys(sounds).forEach((key) => {
		sounds[key].items.forEach((item: HTMLAudioElement) => {
			item.load()
		})
	})
}

export const shouldShowTouchScreen = (animationId: string) => {
	return checkAnimationId(animationId) && isIOS()
}

export const shouldShowAnimation = (animationId: string) => {
	return checkAnimationId(animationId) && !isIOS()
}

export const checkAnimationId = (animationId: string) => {
	return !localStorage.getItem("completed_animation") ||
		localStorage
			.getItem("completed_animation")
			?.split(",")
			?.findIndex((value) => value === animationId) === -1
}

export const isStatusOK = (status: number) => {
	return status && status < 400;
}

export const getLocalSavingProgress = (mode: UnregisterSaveProgressMode) => {
	const localSavingProgress = localStorage.getItem(
		mode === UnregisterSaveProgressMode.Lesson ? lessons_saving_progress : arcade_saving_progress
	);

	if (!localSavingProgress) return null;

	if (localSavingProgress) {
		// @ts-ignore
		return JSON.parse(localSavingProgress);
	}
}

export const setLocalStorage = async (key: string, value: any) => {
	await localStorage.setItem(key, value);
}

export const loadAndPlay = (audio: HTMLAudioElement) => {
	if (audio) {
		audio.load();
		setTimeout(() => {
			audio.play();
			audio.addEventListener("ended", () => removeAudio(audio)
			);
		}, 100);
	}
}

export const removeAudio = (audio: HTMLAudioElement) => {
	audio.pause();
	audio.currentTime = 0;
	// @ts-ignore
	audio = null;
}

export const getProgressData = (mode: UnregisterSaveProgressMode) => {
	const completedLevels = localStorage.getItem(
		mode === UnregisterSaveProgressMode.Lesson ? completed_level : arcades_completed_levels );
	const lessonsProgress = getLocalSavingProgress(mode);
	const result: IRequestProgress[] = [];

	if (completedLevels) {
		completedLevels.split(',').forEach((lessonId) => {
			result.push({
				[mode === UnregisterSaveProgressMode.Lesson ? 'lessonId' : 'levelId']: +lessonId,
				isCompleted: true,
				partId: null
			})
		})
	}

	if (lessonsProgress) {
		Object.keys(lessonsProgress).forEach((lessonId) => {
			const index = result.findIndex(progress => progress[mode === UnregisterSaveProgressMode.Lesson ? 'lessonId' : 'levelId'] === +lessonId);
			if (index !== -1) {
				result[index] = { ...result[index], partId: lessonsProgress[lessonId] }
			}
			if (index === -1) {
				result.push({
					[mode === UnregisterSaveProgressMode.Lesson ? 'lessonId' : 'levelId']: +lessonId,
					isCompleted: false,
					partId: lessonsProgress[lessonId]
				})
			}
		})
	}

	return result
}


interface ISaveUnregisterProgress {
	id: string
	mode: UnregisterSaveProgressMode
	partCountId: number
}

export const saveUnregisterProgress = ({id, mode, partCountId }: ISaveUnregisterProgress) => {
		const localSavingProgress: ILocalSavingProgress | null = getLocalSavingProgress(mode);
		if (!localSavingProgress) {
			setLocalStorage(
				mode === UnregisterSaveProgressMode.Lesson ? lessons_saving_progress : arcade_saving_progress,
				JSON.stringify({ [id]: partCountId })
			).then();
		}
		if (localSavingProgress) {
			// @ts-ignore
			localSavingProgress[id] = partCountId;
			setLocalStorage(
				mode === UnregisterSaveProgressMode.Lesson ? lessons_saving_progress : arcade_saving_progress,
				JSON.stringify(localSavingProgress)
			).then();
			return
		}
};

export const removeLocalProgressById = (mode: UnregisterSaveProgressMode, id: string ) => {
	const savingProgress: ILocalSavingProgress | null = getLocalSavingProgress(mode);

	if (savingProgress && savingProgress.hasOwnProperty(id)) {
		// @ts-ignore
		delete savingProgress[id];
		setLocalStorage(mode === UnregisterSaveProgressMode.Lesson ? lessons_saving_progress : arcade_saving_progress, JSON.stringify(savingProgress)).then();
	}
}
