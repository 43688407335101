import { Dispatch } from 'react';
import { isStatusOK } from '../../../helpers';
import { ActionsType, ISounds } from './types';
import { getSoundsRequest } from './api';

export const actions = {
	setLoading: (value: boolean) => ({ type: "media/SET_LOADING", value } as const),
	setSounds: (value: ISounds | null) => ({ type: 'media/SET_SOUNDS', payload: value } as const),
}

export const getSounds = () => async (dispatch: Dispatch<ActionsType>) => {
	try {
		dispatch(actions.setLoading(true));

		const { status, data } = await getSoundsRequest();
		if (isStatusOK(status)) {
			dispatch(actions.setSounds(data));
		}
	} catch (error: unknown) {
		console.log("Error getSounds", error);
	} finally {
		dispatch(actions.setLoading(false));
	}
}
