import React from "react";
import { useHistory } from "react-router-dom";
import Qs from "qs";
import classNames from "classnames";
import RoundButton from "../../../../../components/buttons/RoundButton";
import arrowNext from "../../../../../assets/icons/blue-arrow-top.svg";
import home from "../../../../../assets/icons/home.svg";
import arrowBack from "../../../../../assets/icons/yellow_back_arrow.svg";
import { ILevelsLinks } from "../../../../../store/modules/studyZone/types";
import { Operand } from "../../../../../types";

interface IProps {
  position: "right" | "left";
  links: ILevelsLinks;
  currentPage: number;
  getNewLevel: (sign: Operand, lastPage: number) => void;
}

/**
 *
 * @param position{'left'||'right'} - Position of bar
 * @param links{Object} - Object with pagination params.
 * @param currentPage{number} - Current page. Position chosen by the user
 * @param getNewLevel{function} - Page switching function
 * @returns {JSX.Element}
 * @constructor
 */
const LevelsBar: React.FC<IProps> = ({
  position,
  links,
  currentPage,
  getNewLevel,
}) => {
  const history = useHistory();

  const getPage = (string: string) => {
    const query = Qs.parse(string, {
      ignoreQueryPrefix: true,
    });

    return Object.keys(query).map((element) => query[element])[0];
  };

  const getImage = () => {
    const lastPage = getPage(links.last)!;

    if (position === "left") {
      if (currentPage === 1) {
        return (
          <RoundButton
            image={home}
            onClick={() => {
              history.push("/home");
            }}
            alt={home}
            className="lessons-nav_circle"
            imageClassName="lessons-nav_circle-house lessons-nav-image"
          />
        );
      }

      return (
        <RoundButton
          onClick={() => {
            getNewLevel("-", +lastPage);
          }}
          alt="back arrow"
          className="lessons-nav_circle "
          image={arrowBack}
          imageClassName="lessons-nav-image lessons-nav_circle-arrow"
        />
      );
    }

    if (position === "right" && currentPage === +lastPage) {
      return null;
    }

    return (
      <RoundButton
        image={arrowNext}
        onClick={() => {
          getNewLevel("+", +lastPage);
        }}
        alt="next arrow"
        className="lessons-nav_circle transform-arrow"
        imageClassName="blue-arrow-image lessons-nav-image"
      />
    );
  };

  return (
    <div
      className={classNames("bar", {
        "bar-left": position === "left",
        "bar-right": position === "right",
      })}
    >
      {getImage()}
    </div>
  );
};

export default LevelsBar;
