import React, { useRef } from "react";
import cn from 'classnames';
import emptyCup from "../../../../../assets/icons/cubs/emptyCub.svg";
import bronzeCup from "../../../../../assets/icons/cubs/bronze.svg";
import silverCup from "../../../../../assets/icons/cubs/silver.svg";
import goldCup from "../../../../../assets/icons/cubs/gold.svg";
import { getLocalCompletedLength } from "../../../../../helpers";
import { ILevelsData } from "../../../../../store/modules/studyZone/types";
import { Authorization } from "../../../../../store/modules/auth/types";
import Lock, { ILockHandle } from '../../../../../components/Lock';

interface IProps {
	item: ILevelsData;
	auth: Authorization | null;
	choseLesson: (id: number) => void;
	onLockedClick: () => void
}

/**
 * @param item{object} - Object with date for create ui level element.
 * @param auth{'authorized' || 'notAuthorized'} - Redux value. Show auth user or no
 * @param choseLesson{function} - function in the parent component
 * @returns {JSX.Element}
 * @constructor
 */
const LevelElement: React.FC<IProps> = ({ item, auth, choseLesson, onLockedClick }) => {
	const lockRef = useRef<ILockHandle>(null);

	const {
		id,
		name,
		completed_lessons: completedLessons,
		lessons_count: lessonsCount,
		order,
		openAt,
		isUnlocked
	} = item;

	const getBaseImg = (img: string) => {
		return <img src={img} alt="cub" className="grid-element-image"/>;
	};

	const getCub = (completed: number, lessonsCount: number) => {
		if (completed < lessonsCount - 3) {
			return getBaseImg(emptyCup);
		}
		if (completed < lessonsCount - 1) {
			return getBaseImg(bronzeCup);
		}
		if (completed === lessonsCount - 1) {
			return getBaseImg(silverCup);
		}
		if (completed === lessonsCount) {
			return getBaseImg(goldCup);
		}

		return getBaseImg(emptyCup);
	};

	const getImage = () => {
		if (
			auth === "notAuthorized" &&
			!localStorage.getItem("completed_level") &&
			isUnlocked
		) {
			return getBaseImg(emptyCup);
		}

		if (auth === "notAuthorized" && isUnlocked) {
			const localCompleted = getLocalCompletedLength();
			// TODO FIX IT CUP
			return getCub(order === 1 ? localCompleted : 0, lessonsCount);
		}

		if (
			auth === "authorized" &&
			isUnlocked &&
			(completedLessons || completedLessons === 0)
		) {
			return getCub(completedLessons, lessonsCount);
		}

		return (
			<Lock ref={lockRef}/>
		);
	};

	const clickElement = (id: number) => {
		if (isUnlocked) {
			choseLesson(id);
		}
		if (!isUnlocked && lockRef.current) {
			lockRef.current.onStartAnimation();
			onLockedClick();
		}
	};

	return (
		<div
			key={id}
			className={cn('grid-element flex-center', {
				'level-element-available': isUnlocked,
				'level-element-forbidden': !isUnlocked
			})}
			onClick={() => clickElement(id)}
			onKeyDown={() => clickElement(id)}
		>
			{getImage()}
			<div className="grid-element-text">{name}</div>
		</div>
	);
};

export default LevelElement;
