import {
	getCoursesDetailsRequest,
	getLearnBookRequest,
	getLessonListRequest,
	getLessonRequest,
	getLevelsRequest, saveLessonProgressRequest,
	setLessonCompletedRequest,
} from "./api";
import { getCompleteLesson, toCamelCase } from "../../../helpers";
import {
	ICourse,
	ILearnBook,
	ILesson,
	ILessonList,
	ILevels,
	ILevelsPage,
	IRedirectBack, ISaveLessonProgressOptions,
	ISetLoading,
} from "./types";
import { ActionsType } from "./types";
import { History } from "history";
import { Dispatch } from "redux";

export const actions = {
	setStudyZoneLoading: (value: boolean) =>
		({ type: "studyZone/STUDY_ZONE_LOADING", value } as const),
	setLevels: (payload: ILevels) =>
		({ type: "studyZone/SET_LEVELS", payload } as const),
	setLessonsList: (payload: ILessonList[]) =>
		({ type: "studyZone/SET_LESSONS_LIST", payload } as const),
	setLesson: (payload: ILesson | null) =>
		({ type: "studyZone/SET_LESSON", payload } as const),
	clearLessons: () => ({ type: "studyZone/CLEAR_LESSONS" } as const),
	clearLesson: () => ({ type: "studyZone/CLEAR_LESSON" } as const),
	clearLevels: () => ({ type: "studyZone/CLEAR_LEVELS" } as const),
	setCurrentCompleteLessons: (prevComplete: number) =>
		({ type: "studyZone/SET_COMPLETE_LESSONS", prevComplete } as const),
	setCoursesDetails: (payload: ICourse) =>
		({ type: "studyZone/SET_COURSES_DETAILS", payload } as const),
	setLearnBook: (payload: ILearnBook[]) =>
		({ type: "studyZone/SET_LEARN_BOOK", payload } as const),
};

/**
 *  GET method, '/levels'.  - Get list of levels
 *
 * @param path {'/levels/course/{id}' || '/levels/course/{course_id}/child/{child_id}' } - Path for request. When user authorized '/levels/child/childId`' else 'levels'
 * @param page {number} - Number with page what user want
 * @param setLocalLoading{function} - Put local loader for pages
 * @param setLoading {function} - Set a locate loading in level pages
 * @param history {Object} - Hook from useHistory.
 * @returns {function(*): Promise<unknown>}
 */
export const getLevels =
	(
		path: string,
		page: number,
		setLocalLoading: ISetLoading = () => {
		},
		history: History
	) =>
		(dispatch: Dispatch<ActionsType>) => {
			dispatch(actions.setStudyZoneLoading(true));
			setLocalLoading(true);

			const params: ILevelsPage = {
				page,
			};

			return getLevelsRequest(path, params)
				.then((res: any) => {
					const currentPage: any = localStorage.getItem("current_page");

					if (res.data && +currentPage === page) {
						dispatch(actions.setLevels(res));
						dispatch(actions.setStudyZoneLoading(false));
						setLocalLoading(false);
					}

					if (res.status === 401 || res.status === 403 || res.status === 500) {
						dispatch(actions.setStudyZoneLoading(false));
						setLocalLoading(false);
						history.push("/");
					}
				})
				.catch((error: unknown) => {
					dispatch(actions.setStudyZoneLoading(false));
					setLocalLoading(false);
					console.log(error);
				});
		};

/**
 * GET method, /lessons/level/{level_id} or /lessons/level/{level_id}/child/{child_id}. - Get list of lesson.
 *
 * @param path {string} - Path for request.
 * @param history {Object} - Hook from useHistory.
 * @returns {function(*): Promise<unknown>}
 */
export const getLessonList =
	(path: string, history: History) => (dispatch: Dispatch<ActionsType>) => {
		dispatch(actions.setStudyZoneLoading(true));

		return getLessonListRequest(path)
			.then((res: any) => {
				if (res.data) {
					const prevComplete = getCompleteLesson(res.data);

					const data = res.data
						.sort((a: any, b: any) => a.order - b.order)
						.map((item: any) => toCamelCase(item));

					dispatch(actions.setLessonsList(data));
					dispatch(actions.setCurrentCompleteLessons(prevComplete));
					dispatch(actions.setStudyZoneLoading(false));
				}

				if (res.status === 401 || res.status === 403 || res.status === 404) {
					dispatch(actions.setStudyZoneLoading(false));
					history.push("/");
				}
			})
			.catch((error: unknown) => {
				console.log(error);
			});
	};

/**
 * GET method '/lessons/{id}' || '/lessons/{id}/child/{child_id}'. Get information about lesson
 *
 * @param path {'/lessons/{id}' || '/lessons/{id}/child/{child_id}'} - Path for request.
 * @param history {Object} - Hook from UseHistory
 * @returns {function(*): Promise<unknown>}
 */
export const getLesson =
	(path: string, history: History) => (dispatch: Dispatch<ActionsType>) => {
		dispatch(actions.setStudyZoneLoading(true));

		return getLessonRequest(path)
			.then((res: any) => {
				if (res.status === 200) {
					dispatch(actions.setLesson(res.data));
					dispatch(actions.setStudyZoneLoading(false));
				}
				if (res.status === 401 || res.status === 403 || res.status === 404) {
					dispatch(actions.setStudyZoneLoading(false));
					history.push("/");
				}
			})
			.catch((error: unknown) => {
				dispatch(actions.setStudyZoneLoading(false));
				console.log(error);
			});
	};

/**
 * POST method '/lessons/{id}/child/{child_id}/mark-as-completed' - Mark what lesson is completed
 *
 * @param lessonId {number} - Number of lesson
 * @param childId {number} - Child id
 * @param redirectBack {Function} - Function what redirect user to lesson page. Animation will be if lesson passed for the first time.
 * @param wasCompleted {boolean} - Shows lesson completed or not.
 * @param history {Object} - Hook from useHistory.
 * @returns {function(*): Promise<unknown>}
 */
export const setLessonCompleted =
	(
		lessonId: number,
		childId: number,
		redirectBack: IRedirectBack,
		wasCompleted: boolean,
		history: History
	) =>
		(dispatch: Dispatch<ActionsType>) => {
			dispatch(actions.setStudyZoneLoading(true));

			return setLessonCompletedRequest(lessonId, childId)
				.then((res: any) => {
					if (res.status === 202) {
						if (!wasCompleted) {
							sessionStorage.setItem("animation_item", lessonId.toString());
						}
						redirectBack(wasCompleted, lessonId);

						dispatch(actions.setStudyZoneLoading(false));
					}
					if (res.status === 401) {
						dispatch(actions.setStudyZoneLoading(false));
						history.push("/");
					}
				})
				.catch((error: unknown) => {
					dispatch(actions.setStudyZoneLoading(false));
					console.log(error);
				});
		};

/**
 * GET method '/courses/${coursesId}' - Get the course details
 *
 * @returns {function(*): Promise<unknown>}
 */
export const getCoursesDetails = () => (dispatch: Dispatch<ActionsType>) => {
	dispatch(actions.setStudyZoneLoading(true));

	const courseId = localStorage.getItem("courses_id");

	return getCoursesDetailsRequest(courseId)
		.then((res: any) => {
			if (res.status === 200) {
				dispatch(actions.setCoursesDetails(res.data));
				dispatch(actions.setStudyZoneLoading(false));
			} else {
				dispatch(actions.setStudyZoneLoading(false));
			}
		})
		.catch((error: unknown) => {
			dispatch(actions.setStudyZoneLoading(false));
			console.log(error);
		});
};

/**
 * GET method '/learn-books/course/{id}' - Get books of learn
 * @param history {Object} - hook from useHistory
 * @param childId {number | null} - id of active children
 * @returns {function(*): Promise<void>}
 */
export const getLearnBook = (childId: number | null, history: History) => (dispatch: Dispatch<ActionsType>) => {
	dispatch(actions.setStudyZoneLoading(true));

	const courseId = localStorage.getItem("courses_id");

	return getLearnBookRequest(childId, courseId)
		.then((res: any) => {
			if (res.data) {
				dispatch(actions.setLearnBook(res.data));
				dispatch(actions.setStudyZoneLoading(false));
			}
			if (res.status === 401 || res.status === 403 || res.status === 500) {
				history.push("/");
				dispatch(actions.setStudyZoneLoading(false));
			}
		})
		.catch((error: unknown) => {
			console.log(error);
		});
};


export const saveLessonProgress = (params: ISaveLessonProgressOptions ) => async (dispatch: Dispatch<ActionsType>) => {
	try {
		 await saveLessonProgressRequest(params);
	} catch (error: unknown) {
		console.log('Error saveLessonProgress ', error)
	}
}
