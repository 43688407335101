import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { actions, getLesson, saveLessonProgress, setLessonCompleted, } from "../../../store/modules/studyZone/actions";
import studyZoneSelector from "../../../store/modules/studyZone/selector";
import LoadingModal from "../../../components/modals/LoadingModal";
import authSelector from "../../../store/modules/auth/selector";
import VideoModule from "../../../components/VideoModule";
import { completedLesson, getApiPath } from "./helpers";
import LessonMainScreen from "./components/LessonMainScreen";
import LessonGameScreen from "./components/LessonGameScreen";
import useDidMountEffect from '../../../hooks/useDidMountEffect';
import { active_child_id, saveUnregisterProgress } from '../../../helpers';
import { UnregisterSaveProgressMode } from '../../../constants/enums';
import useHistoryWithReplace from '../../../hooks/useHistoryWithReplace';
import { setSavedPartId } from '../../../helpers/saveProgress';
import "./lesson.scss";
import { actions as mediaActions, getSounds } from '../../../store/modules/media/actions';
import env from '@beam-australia/react-env';

interface IParams {
  levelId: string;
  lessonId: string;
}

/**
 * @returns {JSX.Element}
 * @constructor
 */
const Lesson = () => {
  const [partsCount, setPartsCount] = useState(0);
  const [isWatchedVideo, setIsWatchedVideo] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isReturnToVideo, setIsReturnToVideo] = useState(false);

  const partCountId = useRef<number>(0);
  const isCompleted = useRef<boolean>(false);
  const { levelId, lessonId } = useParams<IParams>();
  const [history, replaceStore, state] = useHistoryWithReplace();
  const dispatch = useDispatch();

  const { loading, lesson } = useSelector(studyZoneSelector.getStudyStore);
  const auth = useSelector(authSelector.getAuth);

  useEffect(() => {
    document.addEventListener('visibilitychange', visibilityChange);
    dispatch(getSounds());
    return () => {
      dispatch(actions.clearLesson())
      dispatch(mediaActions.setSounds(null));
      saveProgress();
      document.removeEventListener('visibilitychange', visibilityChange);
    }
  }, [])

  const visibilityChange = () => {
    if (document.hidden) {
      saveProgress();
    }
  };

  useEffect(() => {
    const path = getApiPath(auth, lessonId);

    if (path) {
      dispatch(getLesson(path, history));
    }
  }, [auth, getLesson, history, lessonId]);

  // Settings index element with savedPartId id. User will start lesson from that index
  useDidMountEffect(() => {
    setSavedPartId(
      {
        id: lessonId,
        auth,
        mode: UnregisterSaveProgressMode.Lesson,
        state,
        data: lesson,
      },
      { setPartsCount, replaceStore }
    );
  }, [lesson]);

  // Set lesson's part id to ref. For sending to BE if user go out from the lesson.
  useEffect(() => {
    if (lesson && lesson.parts.length > partsCount) {
      partCountId.current = lesson.parts[partsCount].id;
    }
  }, [partsCount, lesson])

  // Saving lesson's part progress
  const saveProgress = () => {
    const childId = localStorage.getItem(active_child_id);
    if (childId && !isCompleted.current) {
      const partId = partCountId.current;
      if (childId && partId) {
        dispatch(saveLessonProgress({
          childId,
          partId
        }))
      }
    }
    if (!childId && !isCompleted.current) {
      saveUnregisterProgress({
        id: lessonId,
        partCountId: partCountId.current,
        mode: UnregisterSaveProgressMode.Lesson
      })
    }
  }

  // Function what redirect user to lesson page. Animation will be if lesson passed for the first time.
  const redirectBack = (wasCompleted: boolean) => {
    if (!wasCompleted && state?.to) {
      history.push({
        pathname: state.to,
        state: {
          completedLessonId: lessonId,
        },
      });
    } else if (state?.to) {
      history.push(state.to);
    } else {
      history.push("/levels");
    }
  };

  const completedPart = () => {
    if (lesson?.parts[partsCount]?.type === "video") {
      setIsWatchedVideo(false);
    }

    setPartsCount((prevState) => prevState + 1);
  };

  const isVideoNext = () => {
    return lesson?.parts[partsCount + 1]?.type === "video";
  };

  const videoHandler = () => {
    setIsReturnToVideo(true);
  };

  const completedVideo = () => {
    if (lesson && !isReturnToVideo && lesson.parts.length !== partsCount + 1) {
      setIsWatchedVideo(true);
    }

    if (lesson && !isReturnToVideo && lesson.parts.length === partsCount + 1) {
      completedPart();
    }

    if (isReturnToVideo) {
      setIsMenuOpen(false);
      setIsReturnToVideo(false);
    }
  };

  if (loading || !lesson?.parts) {
    return (
      <div className="vertical-screen">
        <div className="lesson">
          <LoadingModal isForChild/>
        </div>
      </div>
    );
  }

  if (lessonId && lesson?.parts.length === partsCount) {
    isCompleted.current = true;
    completedLesson(
      auth,
      lessonId,
      lesson,
      setLessonCompleted,
      redirectBack,
      history,
      dispatch
    );
  }

  if (
    lesson?.parts[partsCount] &&
    ((lesson.parts[partsCount].type === "video" && !isWatchedVideo) ||
      isReturnToVideo)
  ) {
    let source;

    if (!isReturnToVideo) {
      source = lesson.parts[partsCount].content;
    } else {
      const watchedVideo = lesson?.parts
        ?.slice(0, partsCount + 1)
        ?.filter((item) => item.type === "video");

      const lastVideoIndex = lesson?.parts.findIndex(
        (item) => item.id === watchedVideo[watchedVideo.length - 1].id
      );
      source = lesson.parts[lastVideoIndex].content;
    }

    return (
      <>
        {env("NODE_ENV") !== 'production' && (
          <button style={{ position: 'absolute', zIndex: 20, top: 20, left: 12 }}
                  onClick={completedVideo}
          >
            Complete parts
          </button>
        )}
        <VideoModule completedPart={completedVideo} source={source}/>
      </>
    )
  }

	if (lesson.parts[partsCount] && lesson.parts[partsCount].type === "game") {
		return (
			<LessonGameScreen
				isMenuOpen={isMenuOpen}
				setIsMenuOpen={setIsMenuOpen}
				setPartsCount={setPartsCount}
				configData={lesson.parts[partsCount].content}
				videoHandler={videoHandler}
				levelId={levelId}
			/>
		);
	}

  return (
    <LessonMainScreen
      setIsWatchedVideo={setIsWatchedVideo}
      completedPart={completedPart}
      isVideoNext={isVideoNext()}
      levelId={levelId!}
    />
  );
};

export default Lesson;
