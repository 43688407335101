import { AppStateType } from "../../rootReducer";

const getLoading = (state: AppStateType) => state.children.loading;
const getChildrenList = (state: AppStateType) => state.children.childrenList;
const getActiveChild = (state: AppStateType) => state.children.activeChild;
const getActiveChildId = (state: AppStateType) => state.children.activeChildId;

export default {
  getLoading,
  getChildrenList,
  getActiveChild,
  getActiveChildId,
};
