import React, { useState } from "react";
import classNames from "classnames";
import lock from "../../../../assets/icons/lockLevel.svg";

interface Props {
  icon: string;
  onClick: () => void;
  available: boolean;
}

/**
 * Navigation Element in Home Page
 *
 * @param icon{File} - Image. Image in navigation Block
 * @param onClick{function} - Event push in inner url
 * @param available{boolean} - Shows available button or not
 * @returns {JSX.Element}
 * @constructor
 */

const NavElement: React.FC<Props> = ({ icon, onClick, available }) => {
  const [clazzLock, setClazzLock] = useState(
    "nav-element__img nav-element__lock"
  );

  const clickItem = () => {
    if (available) {
      onClick();
    } else {
      setClazzLock("nav-element__img nav-element__lock lock-element-animate");
    }
  };

  const getLock = () => {
    if (!available) {
      return (
        <img
          className={clazzLock}
          onAnimationEnd={() => {
            setClazzLock("nav-element__img nav-element__lock");
          }}
          src={lock}
          alt="lock"
        />
      );
    }

    return null;
  };

  return (
    <div
      className={classNames("nav-element flex-center", {
        "nav-element_white-background": available,
        "nav-element_gray-background": !available,
      })}
      onClick={clickItem}
      onKeyDown={onClick}
    >
      <img className="nav-element__img" src={icon} alt="nav" />
      {getLock()}
    </div>
  );
};

export default NavElement;
