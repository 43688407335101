import React, { FC } from "react";
import Modal from "react-modal";
import cn from 'classnames';
import childLoader from "../../../assets/icons/loaders/preloader_2_for_CSS-1.svg";
import parentLoader from "../../../assets/icons/loaders/perehod_3_for_CSS.svg";
import { LoadingModalMode } from "../../../constants/enums";

interface ILoadingModal {
  isForChild?: boolean;
  mode?: LoadingModalMode;
}

/**
 *
 *
 * @param isForChild {boolean} - Shows which loader to show
 * @param mode {LoadingModalMode} - Mode of Loader
 * @returns {JSX.Element}
 * @constructor
 */
const LoadingModal: FC<ILoadingModal> = ({
  isForChild = true,
  mode = LoadingModalMode.Dark,
}) => {
  return (
    <Modal
      isOpen
      ariaHideApp={false}
      className="loading-modal flex-center"
      overlayClassName={cn('overlay', {'overlay-dark': mode === LoadingModalMode.Dark, 'overlay-light': mode === LoadingModalMode.Light})}
      contentLabel="Example Modal"
    >
      {isForChild && <img src={childLoader} alt="" className="loader-icon" />}
      {!isForChild && <img src={parentLoader} alt="" className="loader-icon" />}
    </Modal>
  );
};

export default LoadingModal;
