import React from "react";
import { ReactComponent as PlayButton } from "../../assets/icons/play-button2.svg";

interface IProps {
  animationStartButton: (e: any) => void;
}

const ClickHandlerPage: React.FC<IProps> = ({ animationStartButton }) => {
  return (
    <div className="vertical-screen">
      <div className="lesson lesson__animation" />
      <div className="flex-center lesson__animation-button">
        <PlayButton
          onClick={animationStartButton}
          onKeyDown={animationStartButton}
          className="lesson__play-button"
        />
      </div>
    </div>
  );
};

export default ClickHandlerPage;
