import React, { Component, ErrorInfo, ReactNode } from "react";
import Error500 from '../../pages/Error500';
import { connect } from 'react-redux';
import { AppStateType } from '../../store/rootReducer';
import { getIsError } from '../../store/modules/native/selector'
import * as Sentry from '@sentry/browser';

interface Props {
	children?: ReactNode;
	isError: boolean
}

interface State {
	hasError: boolean;
}

class ErrorBoundary extends Component<Props, State> {
	public state: State = {
		hasError: false
	};

	public static getDerivedStateFromError(error: Error): State {
		return { hasError: true };

	}

	public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
		console.error("Uncaught error:", error, errorInfo);
		Sentry.captureException(error);
	}

	public render() {
		if (this.state.hasError || this.props.isError) {
			return <Error500 />
		}

		return this.props.children;
	}
}

const mapStateToProps = (state: AppStateType ) => ({
	isError: getIsError(state)
})



export default connect(mapStateToProps)(ErrorBoundary);
