import { IChild } from "./types";
import { ActionsType } from "./types";

const InitialState = {
	loading: false,
	childrenList: null as null | Array<IChild>,
	isShowSuccessAlert: false,
	activeChild: null as null | IChild,
	activeChildId: null as null | number,
};

export type InitialStateType = typeof InitialState;

export const childrenReducer = (
	state = InitialState,
	action: ActionsType
): InitialStateType => {
	switch (action.type) {
		case "children/CHILDREN_LOADING":
			return {
				...state,
				loading: action.value,
			};
		case "children/SET_ACTIVE_CHILDREN":
			return {
				...state,
				activeChild: action.payload,
			};
		case "children/SET_CHILDREN_LIST":
			return {
				...state,
				childrenList: action.payload,
			};
		case "children/CLEAR_CHILDREN_LIST":
			return {
				...state,
				childrenList: null,
			};
		case "children/SET_ACTIVE_CHILD_ID":
			return {
				...state,
				activeChildId: action.childId,
			};

		default:
			return state;
	}
};
