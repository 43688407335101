import React, { useState } from 'react'
import { ReactComponent as Boy } from '../../assets/image/boy.svg';
import { ReactComponent as Girl } from '../../assets/image/girl.svg';
import { ReactComponent as Viber } from '../../assets/icons/viber.svg';
import { ReactComponent as Telegram } from '../../assets/icons/_telegram.svg';
import { useTranslation } from 'react-i18next';
import Modal from "react-modal";
import MainButton from '../../components/buttons/MainButton';
import useWindowSize from '../../hooks/useWindowSize';
import './error500.scss';


const Error500 = () => {
	const [isModal, setIsModal] = useState(false);
	const { t } = useTranslation();
	const { width } = useWindowSize()

	// @ts-ignore
	const isMobile = width < 500;

	return (
		<div className="error-page">
			<div className="error-page__image error-page__image-boy">
				{/*<img src={Boy} alt=""/>*/}
				<Boy/>
			</div>
			<div className="error-page__content">
				<h3>{t('error.title')}</h3>
				<p className="error-page__subtitle">{t('error.subTitle')}</p>
				<div className="error-page__buttons">
					<button className="btn btn__primary" onClick={() => window.location.reload()}>
						{t('error.reload')}
					</button>
					<button className="btn btn__secondary" onClick={() => setIsModal(true)}>
						{t('error.details')}
					</button>
				</div>
			</div>
			<div className="error-page__image error-page__image-girl">
				{/*<img src={Girl} alt=""/>*/}
				<Girl/>
			</div>
			<Modal
				isOpen={isModal}
				ariaHideApp={false}
				contentLabel="Example Modal"
				overlayClassName="overlay overlay-dark"
				className="success-modal error-page__modal"
			>
				<h4 className="error-page__modal-title">{t('error.modal_title')}</h4>
				<p className="error-page__modal-point"> {t('error.modal_point1')}</p>
				<p className="error-page__modal-point"> {t('error.modal_point2')}</p>
				<p className="error-page__modal-point"> {t('error.modal_point3')}</p>
				<p className="error-page__modal-point"> {t('error.modal_point4')}</p>
				<p className="error-page__modal-point bold"> {t('error.modal_point5')}</p>
				<div className="error-page__modal-links">
					<a className="error-page__modal-viber" href={`viber://chat?number=%2B380671154340`} target="_blank" rel="nofollow" aria-label="Viber">
						<Viber/>
					</a>
					<a className="error-page__modal-telegram" href="tg://resolve?domain=optimakidssupport" target="_blank" rel="noreferrer">
						<Telegram/>
					</a>
				</div>
				<MainButton
					type="submit"
					text="Зрозуміло"
					className="login__button-green error-page__modal-button"
					onClick={() => setIsModal(false)}
				/>
			</Modal>

		</div>
	)
}

export default Error500;
