import React, { forwardRef, useImperativeHandle, useState } from 'react';
import cn from 'classnames';
import lock from '../../assets/icons/padlock.svg';
import lockFilled from '../../assets/icons/lock_filled.svg';
import { LockMode } from '../../constants/enums';

interface ILockProps {
	mode?: LockMode
}

export interface ILockHandle {
	onStartAnimation: () => void
}

const Lock = forwardRef<ILockHandle, ILockProps>(({mode = LockMode.Common}, ref) => {
	const [isAnimated, setIsAnimated] = useState(false);

	useImperativeHandle(ref, () => ({
		onStartAnimation() {
			setIsAnimated(true);
		}
	}))
	return (
		<img
			onAnimationEnd={() => setIsAnimated(false)}
			src={mode === LockMode.Common ? lock : lockFilled}
			alt="lock"
			className={cn('grid-element-image', { 'lock-element-animate': isAnimated })}
		/>
	);
});

export default Lock;
