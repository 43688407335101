export enum LoadingModalMode {
	Light = 'Light',
	Dark = 'Dark'
}

export enum LockMode {
	Filled,
	Common
}

export enum UnregisterSaveProgressMode {
	Lesson = 'Lesson',
	Game = 'Game'
}

export enum Seasons {
	Winter = 'winter',
	Summer = 'summer',
	Spring = 'spring',
	Autumn = 'autumn'
}

export enum MediaPathMode {
	LessonLevel = 'LessonLevel',
	LessonLesson = 'LessonLesson',
	ArcadeCategories = 'ArcadeCategories',
	Arcades = 'Arcades',
	ArcadeLevels = 'ArcadeLevels',
	HomeVideo = 'HomeVideo'
}

export enum NodeENV {
	Production = 'production',
	Development = 'development'
}
