import React from 'react';
import './index.scss';
import { ReactComponent as PlayIcon } from "../../../assets/icons/play_market.svg";
import { ReactComponent as PlayIconText } from "../../../assets/icons/play_market_text.svg";

const getAndroidVersion = (ua?: any) => {
  ua = (ua || navigator.userAgent).toLowerCase();
  const match = ua.match(/android\s([0-9\.]*)/i);
  return match ? match[1] : undefined;
};

const PlayMarket = () => {
  const version = +parseInt(getAndroidVersion(), 10);

  if (!version || (version && version < 10)) {
    return null;
  }

  return (
    <a
      href="market://details?id=com.optimaschool.kids.mobile"
      className="play_market__container play_market__animation"
      >
      <PlayIcon className="play_market__icon" />
      <PlayIconText className="play_market__text"/>
      <span className="play_market__download">
        Завантажити додаток в Google Play
      </span>
    </a>
  );
};

export default PlayMarket;
