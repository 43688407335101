import React, { MutableRefObject, useEffect, useRef, useState } from "react";
import CornerButton from "../../../../../components/buttons/CornerButton";
import GameModuleAudioWrapper from "../../../../../components/GameModuleAudioWrapper";
import LessonCornerMenu from "../LessonCornerMenu";
import {
  animationStart,
  endOfAnimatedAudio,
  lesson_game_id,
  uploadSounds,
  removeAudio,
  checkAnimationId
} from "../../../../../helpers";
import ClickHandlerPage from "../../../../../components/ClickHandlerPage";
import { useSelector } from 'react-redux';
import mediaSelector from '../../../../../store/modules/media/selector';
import { IReadySounds } from '../../../../../store/modules/media/types';

const audioBoy = require("../../../../../assets/audio/lesson_boy.mp3");

interface IProps {
  isMenuOpen: boolean;
  setIsMenuOpen: (prevState: boolean) => void;
  setPartsCount: (prevState: number) => void;
  configData: string;
  videoHandler: () => void;
  levelId: string;
}

const LessonGameScreen: React.FC<IProps> = ({
  isMenuOpen,
  setIsMenuOpen,
  setPartsCount,
  configData,
  videoHandler,
  levelId,
}) => {
  const [isAnimation, setIsAnimation] = useState(checkAnimationId(lesson_game_id));

  const { sounds }  = useSelector(mediaSelector.getMediaStore);

  const [isTouchScreen, setIsTouchScreen] = useState(false)
  let audio: MutableRefObject<HTMLAudioElement> = useRef(new Audio(audioBoy));
  const [uploadedSounds, setUploadedSounds] = useState<IReadySounds | null>(null);

  useEffect(() => {
    if (checkAnimationId(lesson_game_id)) {
      animationStartButton();
    }

    return () => {
      if (audio.current) removeAudio(audio.current);
      endOfAnimatedAudio(audio.current, setIsAnimation, lesson_game_id);
    };
  }, []);

  useEffect(() => {
    if (sounds) {
      setUploadedSounds(uploadSounds(sounds));
    }
  }, [sounds]);

  const animationStartButton = () => {
      audio.current = new Audio(audioBoy);
      setIsTouchScreen(false);
      animationStart(audio.current!, setIsAnimation, lesson_game_id, () => {setIsTouchScreen(true)});
  };

  if (isTouchScreen) {
    return <ClickHandlerPage animationStartButton={animationStartButton} />;
  }

  return (
    <div className="vertical-screen">
      <LessonCornerMenu
        isAnimation={isAnimation}
        isMenuOpen={isMenuOpen}
        videoHandler={videoHandler}
        levelId={levelId}
      />

      <CornerButton
        isMenuOpen={isMenuOpen}
        setIsMenuOpen={setIsMenuOpen}
        isAnimation={isAnimation}
      />

      <GameModuleAudioWrapper
        isPause={isMenuOpen}
        setPartsCount={setPartsCount}
        configData={configData}
        isAnimation={isAnimation}
        audio={uploadedSounds}
      />
    </div>
  );
};

export default LessonGameScreen;
