import React from "react";
import classNames from "classnames";
import "./cornerButton.scss";

interface IProps {
  isMenuOpen: boolean;
  setIsMenuOpen: (prevState: any) => void;
  isAnimation: boolean;
}

const CornerButton: React.FC<IProps> = ({
  isMenuOpen,
  setIsMenuOpen,
  isAnimation,
}) => {
  const handleClick = () => {
    if (isAnimation) return;
    setIsMenuOpen((prevState: boolean) => !prevState)
  };

  return (
      <div onClick={handleClick}
           onKeyDown={handleClick}
           className={classNames("cornerButton", {active: isMenuOpen})}>

        <div className={classNames("cornerButton__arrow", { active: isMenuOpen })}
      />
    </div>
  );
};

export default CornerButton;
