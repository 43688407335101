import React from 'react';
import '../PlayMarket/index.scss';
import { ReactComponent as PlayIcon } from "../../../assets/icons/app_store.svg";
import { ReactComponent as PlayIconText } from "../../../assets/icons/app_store_text.svg";

export const isiOS = (ua?: any) => {
  ua = (ua || navigator.userAgent).toLowerCase();
  // Check for iPhone or iPad
  return /iphone|ipad/.test(ua) || (ua.includes('macintosh') && 'ontouchend' in document);

};

const AppStore = () => {

  if (!isiOS()) {
    return null;
  }

  return (
    <a
      href="https://apps.apple.com/us/app/optima-kids/id6465680883"
      className="play_market__container play_market__animation-store"
      target="_blank"
      >
      <PlayIcon className="play_market__icon" />
      <PlayIconText className="play_market__text"/>
      <span className="play_market__download">
        Завантажити додаток в App Store
      </span>
    </a>
  );
};

export default AppStore;
