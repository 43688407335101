import React, { FC } from 'react';
import { IArcadeLevel } from '../../../../../store/modules/gameZone/types';
import ArcadesLevelElement from '../ArcadesLevelElement';
import { animation_item, arcades_completed_levels } from '../../../../../helpers';
import { useSelector } from 'react-redux';
import authSelector from '../../../../../store/modules/auth/selector';
import { IRedirectToGame } from '../../type';

interface ILevelList {
	arcadeLevels: IArcadeLevel[]
	onLevelClick: (params: IRedirectToGame) => void
}


const LevelList: FC<ILevelList> = ({ arcadeLevels, onLevelClick }) => {
	const auth = useSelector(authSelector.getAuth);

	const completedLevel = localStorage.getItem(arcades_completed_levels);
	const animatedId = sessionStorage.getItem(animation_item)
	return (
		<div className="lessons-grid">
			{arcadeLevels.map(({ id, name, isAvailable, isCompleted, savedPartId }) => (
				<ArcadesLevelElement
					key={id}
					levelId={id}
					name={name}
					isAvailable={isAvailable}
					isCompleted={(auth === 'authorized' && isCompleted) || !!(auth === 'notAuthorized' && completedLevel && completedLevel.split(',')?.some(el => el === id.toString()) )}
					savedPartId={savedPartId}
					onLevelClick={onLevelClick}
					isAnimated={!!animatedId && +animatedId === id}
				/>
			))}
		</div>
	);
};

export default LevelList;
