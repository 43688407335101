import { ActionsType, IProfile } from "./types";

const InitialState = {
  loading: false,
  profile: null as IProfile | null,
  isShowSuccessAlert: false,
  isAdmin: false,
  verifyPhoneCount: 0,
};
type InitialStateType = typeof InitialState;

export const profileReducer = (
  state = InitialState,
  action: ActionsType
): InitialStateType => {
  switch (action.type) {
    case "profile/SAVE_PROFILE":
      return {
        ...state,
        profile: action.payload,
      };
    case "profile/PROFILE_LOADING":
      return {
        ...state,
        loading: action.value,
      };
    case "profile/CLEAR_PROFILE":
      return {
        ...state,
        profile: null,
      };
    case "profile/SET_SUCCESS_ALERT_PROFILE":
      return {
        ...state,
        isShowSuccessAlert: action.value,
      };
    case "profile/SET_ADMIN":
      return {
        ...state,
        isAdmin: action.value,
      };
      case "profile/SET_VERIFY_PHONE_COUNT":
      return {
        ...state,
        verifyPhoneCount: action.value,
      };
    default:
      return state;
  }
};
