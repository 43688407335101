import React, { lazy } from "react";
import { Route } from "react-router-dom";

const CabinetRouts = lazy(
  () => import(/* webpackChunkName: "cabinet" */ "./routes/cabinetRouts")
);
const SettingsRouts = lazy(
  () => import(/* webpackChunkName: "setting" */ "./routes/settingsRouts")
);

interface Props {
  path: string;
  id: any;
}

const LazyRouter: React.FC<Props> = ({ id, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        switch (id) {
          case "cabinet":
            // @ts-ignore
            return <CabinetRouts {...props} />;
          case "settings":
            // @ts-ignore
            return <SettingsRouts {...props} />;

          default:
            return null;
        }
      }}
    />
  );
};

export default LazyRouter;
