import React, { FC } from 'react';
import Modal from "react-modal";
import { ReactComponent as CloseModal } from "../../../assets/icons/close_modal.svg";
import android_QR from '../../../assets/image/qr_android_vector.svg'
import ios_QR from '../../../assets/image/qr_ios_vector.svg'
import './style.scss';

interface ILaptopQrModal {
  onClose: () => void
}

const LaptopQrModal: FC<ILaptopQrModal> = ({ onClose }) => {
  return (
    <Modal
      isOpen
      ariaHideApp={false}
      contentLabel="Example Modal"
      overlayClassName="overlay overlay-dark"
      className="qr-modal"
    >

      <CloseModal className="qr-modal__close" onClick={onClose}/>
      <h4 className="qr-modal__title">
        Відскануйте QR-код, щоб завантажити застосунок Optima Kids
      </h4>
      <div className="qr-modal__container">
        <div className="qr-modal__box">
          <p>Android</p>
          <img src={android_QR} alt="android"/>
        </div>
        <div className="qr-modal__line"></div>
        <div className="qr-modal__box">
          <p>IOS</p>
          <img src={ios_QR} alt="ios"/>
        </div>
      </div>

    </Modal>
  );
};

export default LaptopQrModal;
