import React from "react";
import { Route } from "react-router-dom";
import { connect } from "react-redux";
import authSelector from "../store/modules/auth/selector";
import { AppStateType } from "../store/rootReducer";
import { Authorization } from "../store/modules/auth/types";

interface IMapState {
  auth: Authorization | null;
}

interface IOwnProperties {
  component: React.FunctionComponentFactory<any>;
  path: string;
}

type Props = IMapState & IOwnProperties;

const PublicRouter: React.FC<Props> = ({
  component: Component,
  auth,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        return (
          <>
            <Component {...props} exact />
          </>
        );
      }}
    />
  );
};

const mapStateToProps = (state: AppStateType): IMapState => ({
  auth: authSelector.getAuth(state),
});

export default connect(mapStateToProps)(PublicRouter);
