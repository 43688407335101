import React, {
  useEffect,
  FC,
  useState,
  MutableRefObject,
  useRef,
} from "react";
import "./index.scss";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import authSelector from "../../../store/modules/auth/selector";
import gameZoneSelector from "../../../store/modules/gameZone/selector";
import {
  actions,
  getArcadeLevels,
} from "../../../store/modules/gameZone/actions";
import LoadingModal from "../../../components/modals/LoadingModal";
import {
  LoadingModalMode,
  MediaPathMode,
  UnregisterSaveProgressMode,
} from "../../../constants/enums";
import LevelList from "./components/LevelList";
import home from "../../../assets/icons/home.svg";
import RoundButton from "../../../components/buttons/RoundButton";
import {
  getIsPortrait,
  getLocalSavingProgress,
  removeAudio,
} from "../../../helpers";
import arrow from "../../../assets/icons/yellow_back_arrow.svg";
import useOrientation from "../../../hooks/useOrientation";
import cn from "classnames";
import childrenSelector from "../../../store/modules/children/selector";
import ProgressModal from "../../../components/modals/ProgressModal";
import { IRedirectToGame } from "./type";
import { ILocalSavingProgress } from "../../../store/modules/studyZone/types";
import useMediaPath from "../../../hooks/useMediaPath";

const progressAudio = require("../../../assets/audio/progress.mp3");

interface IOnLevelClick {
  levelId: number;
  savedPartId: number | null;
}

export interface IParams {
  categoryId: string;
  gameId: string;
}

interface IGameLevels {}

const GameLevels: FC<IGameLevels> = () => {
  const { gameId, categoryId } = useParams<IParams>();
  const history = useHistory();
  const [orientation] = useOrientation();
  const dispatch = useDispatch();
  let audio: MutableRefObject<HTMLAudioElement | null> = useRef(null);
  const redirectDataCached = useRef<IOnLevelClick>();
  const mediaPath = useMediaPath(MediaPathMode.ArcadeLevels);

  const auth = useSelector(authSelector.getAuth);
  const arcadeLevels = useSelector(gameZoneSelector.getArcadeLevels);
  const activeChildId = useSelector(childrenSelector.getActiveChildId);

  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    // TODO change path when BE will be updated;
    const path = `arcade-levels/${gameId}`;
    if (auth && path) {
      dispatch(getArcadeLevels({ path, activeChildId }, { history }));
    }
    return () => {
      dispatch(actions.setArcadeLevels(null));
    };
  }, [activeChildId, auth, dispatch, gameId, history]);

  // Modal audio upload
  useEffect(() => {
    audio.current = new Audio(progressAudio);
    if (audio.current) {
      audio.current.load();
    }

    return () => {
      if (audio.current) {
        removeAudio(audio.current);
        audio.current.removeEventListener("ended", () =>
          removeAudio(audio.current!)
        );
      }
    };
  }, []);

  const progressModalBackgroundClickHandler = () => {
    setIsModalOpen(false);
    if (audio.current) {
      removeAudio(audio.current);
    }
  };

  const onLevelClickHandler = ({ levelId, savedPartId }: IRedirectToGame) => {
    redirectDataCached.current = { savedPartId, levelId };

    if (auth === "notAuthorized") {
      const localSavingProgress: ILocalSavingProgress | null =
        getLocalSavingProgress(UnregisterSaveProgressMode.Game);
      if (localSavingProgress && localSavingProgress.hasOwnProperty(levelId)) {
        redirectDataCached.current = {
          levelId,
          savedPartId: localSavingProgress[levelId],
        };
      }
    }

    if (redirectDataCached.current?.savedPartId) {
      setIsModalOpen(true);
      setTimeout(() => {
        if (audio.current) {
          audio.current.play();
          audio.current.addEventListener("ended", () =>
            removeAudio(audio.current!)
          );
        }
      }, 0);
    }

    if (!redirectDataCached.current?.savedPartId) {
      redirectToGame({ levelId, savedPartId });
    }
  };

  const redirectToGame = ({ levelId, savedPartId }: IRedirectToGame) => {
    redirectDataCached.current = undefined;
    history.push({
      pathname: `/game-categories/${categoryId}/game/${gameId}/level/${levelId}`,
      state: {
        partId: savedPartId,
      },
    });
  };

  const getButtons = () => {
    return (
      <>
        <RoundButton
          image={home}
          onClick={() => {
            history.push("/home");
          }}
          alt="home"
          // className={`${homeClass} lessons-nav_circle position-fixed`}
          className={cn("lessons-nav_circle position-fixed ", {
            "position__left-bottom": getIsPortrait(orientation),
            "position__right-bottom": !getIsPortrait(orientation),
          })}
          // imageClassName={`lessons-nav_circle-house  lessons-nav-image ${imageHomeClass}`}
          imageClassName={cn("lessons-nav_circle-house  lessons-nav-image ", {
            "game-nav__image-transform": getIsPortrait(orientation),
          })}
        />

        <RoundButton
          image={arrow}
          onClick={() => {
            history.push(`/game-categories/${categoryId}`);
          }}
          alt="arrow"
          // className={`${arrowClass} lessons-nav_circle position-fixed`}
          className={cn("lessons-nav_circle position-fixed ", {
            "position__left-top game-nav__image-arrow":
              getIsPortrait(orientation),
            "position__left-bottom": !getIsPortrait(orientation),
          })}
          // imageClassName={`lessons-nav-image ${imageArrowClass}`}
          imageClassName={cn("lessons-nav-image", {
            "game-nav__image-transform  game-nav__image-arrow":
              getIsPortrait(orientation),
            "lessons-nav_circle-arrow": !getIsPortrait(orientation),
          })}
        />
      </>
    );
  };

  if (!arcadeLevels) {
    return (
      <div
				className="vertical-screen"
				style={{
					background: `center/cover no-repeat url(${mediaPath}), linear-gradient(63.18deg, #845EC2 0%, #00C9B7 100%)`,
				}}
			>
        <div
					className="levels"
					style={{
						backdropFilter: mediaPath?.includes("small") ? "blur(5px)" : "none",
					}}
				>
          <LoadingModal mode={LoadingModalMode.Light} isForChild />
        </div>
      </div>
    );
  }

  return (
    <>
      <div
        className="vertical-screen"
        style={{
          background: `center/cover no-repeat url(${mediaPath}), linear-gradient(63.18deg, #845EC2 0%, #00C9B7 100%)`,
        }}
      >
        <div
          style={{
            backdropFilter: mediaPath?.includes("small") ? "blur(5px)" : "none",
          }}
          className="game-level"
        >
          <div></div>
          {arcadeLevels && (
            <LevelList
              arcadeLevels={arcadeLevels}
              onLevelClick={onLevelClickHandler}
            />
          )}
          <div></div>
        </div>
        {isModalOpen && (
          <ProgressModal
            onBackgroundClick={progressModalBackgroundClickHandler}
            onPlayClick={() => redirectToGame(redirectDataCached.current!)}
            onRestartClick={() =>
              redirectToGame({
                ...redirectDataCached.current!,
                savedPartId: null,
              })
            }
          />
        )}
      </div>
      {getButtons()}
    </>
  );
};

export default GameLevels;
