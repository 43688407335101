import React, { MutableRefObject, useEffect, useRef, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { getLessonList, actions } from "../../../store/modules/studyZone/actions";
import studyZoneSelector from "../../../store/modules/studyZone/selector";
import Lessons from "./components/Lessons";
import LessonsNav from "./components/LessonsNav";
import LoadingModal from "../../../components/modals/LoadingModal";
import authSelector from "../../../store/modules/auth/selector";
import RegisterModal from "../../../components/modals/RegisterModal";
import { ILocalSavingProgress } from "../../../store/modules/studyZone/types";
import "./lessonsList.scss";
import { IOnLessonClick } from './type';
import ProgressModal from '../../../components/modals/ProgressModal';
import { completed_level, getLocalSavingProgress, removeAudio } from '../../../helpers';
import { MediaPathMode, UnregisterSaveProgressMode } from '../../../constants/enums';
import childrenSelector from '../../../store/modules/children/selector';
import useMediaPath from '../../../hooks/useMediaPath';

const progressAudio = require("../../../assets/audio/progress.mp3");

interface IParams {
	id?: string;
}

/**
 * Gel list of Lessons in Study zone
 *
 * @returns {JSX.Element}
 */
const LessonsList = () => {
	const [isModalOpen, setIsModalOpen] = useState(false);
	const redirectDataCached = useRef<IOnLessonClick>();
	let audio: MutableRefObject<HTMLAudioElement | null> = useRef(null);
	const path = useMediaPath(MediaPathMode.LessonLesson);

	const { id } = useParams<IParams>();
	const { t } = useTranslation();
	const history = useHistory();
	const dispatch = useDispatch();

	const auth = useSelector(authSelector.getAuth);
	const { loading, lessonsList, prevComplete } =  useSelector(studyZoneSelector.getStudyStore);
	const activeChildId = useSelector(childrenSelector.getActiveChildId);

	// Modal audio upload
	useEffect(() => {
    audio.current = new Audio(progressAudio);
    if (audio.current) {
      audio.current.load();
    }

		return () => {
			if (audio.current) removeAudio(audio.current);
		}
	}, [])

	useEffect(() => {
		const path = getApiPath();
		if (path && auth) {
			dispatch(getLessonList(path, history));
		}
		return () => {
			dispatch(actions.clearLessons());
		};
	}, [auth, getLessonList]);

	const getApiPath = () => {
		if (auth === "authorized") {
			return `lessons/level/${id}/child/${activeChildId}`;
		}

		if (auth === "notAuthorized") {
			return `lessons/level/${id}`;
		}

		return null;
	};

	const getModal = () => {
		const completedLevel = localStorage.getItem(completed_level);
		if (completedLevel && lessonsList && (completedLevel.split(",").length === lessonsList.length)) {
			return (
				<RegisterModal
					laterClick={() => {
					}}
					registerClick={() => {
						history.push("/auth/sign");
					}}
					mainButtonText={t("common.RegisterLevelModal.registerButton")}
					showLetterButton={false}
					mainText={t("common.RegisterLevelModal.text")}
					loginClick={() => history.push("/auth/login")}
				/>
			);
		}

		return null;
	};

	const onLessonClickHandler = ({ lessonId, partId }: IOnLessonClick) => {
		redirectDataCached.current = { partId, lessonId };

		if (auth === 'notAuthorized') {
			const localSavingProgress: ILocalSavingProgress | null = getLocalSavingProgress(UnregisterSaveProgressMode.Lesson);
			if (localSavingProgress && localSavingProgress.hasOwnProperty(lessonId)) {
				redirectDataCached.current = { lessonId, partId: localSavingProgress[lessonId] }
			}
		}
		if (redirectDataCached.current?.partId) {
			setIsModalOpen(true);
				setTimeout(() => {
          if (audio.current) {
					audio.current.play();
					audio.current.addEventListener("ended", () => removeAudio(audio.current!))
          }
				}, 0)
		}
		if (!redirectDataCached.current?.partId) {
			redirectToLesson({ lessonId, partId })
		}
	}

	const redirectToLesson = ({ lessonId, partId }: IOnLessonClick) => {
		redirectDataCached.current = undefined;
		history.push({
			pathname: `/levels/${id}/lessons/${lessonId}`,
			state: {
				to: `/levels/${id}`,
				partId: partId
			},
		});
	}

	const progressModalBackgroundClickHandler = () => {
		setIsModalOpen(false);
		if (audio.current) {
			removeAudio(audio.current);
		}
	}

	if (loading || !lessonsList) {
		return (
			<div
				className="vertical-screen"
				style={{background: `center/cover no-repeat url(${path}), linear-gradient(102.81deg, #60BDFB 0%, #00E5E4 70.73%, #EAFFFF 100%)`}}
			>
				<div className="lessons">
					<LoadingModal isForChild/>
				</div>
			</div>
		);
	}

	return (
		<div
			className="vertical-screen"
			style={{background: `center/cover no-repeat url(${path}), linear-gradient(102.81deg, #60BDFB 0%, #00E5E4 70.73%, #EAFFFF 100%)`}}
		>
			{getModal()}
			{isModalOpen && <ProgressModal
				onBackgroundClick={progressModalBackgroundClickHandler}
				onPlayClick={() => redirectToLesson(redirectDataCached.current!)}
				onRestartClick={() => redirectToLesson({ ...redirectDataCached.current!, partId: null })}
			/>}
			<div
				className="lessons"
				style={{backdropFilter: path?.includes('small') ?  'blur(5px)' : 'none'}}
			>
				<Lessons lessonsList={lessonsList} auth={auth} onLessonClick={onLessonClickHandler}/>
				<LessonsNav
					lessonsList={lessonsList}
					prevComplete={prevComplete}
					auth={auth}
				/>
			</div>
		</div>
	);
};


export default LessonsList;
