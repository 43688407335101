import React from "react";
import Modal from "react-modal";
import classNames from "classnames";
import { IHints } from "../../../pages/Home";
import "./hintModal.scss";

interface Props {
  onClick: () => void;
  elements: IHints[];
  textButton: string;
  horizontal?: boolean;
}

/**
 *
 * @param onClick{function} - function what close modal
 * @param elements{Array} - An array of items to display tips
 * @param textButton{string} - text in button
 * @param horizontal{boolean} - Indicates whether the button will only be displayed horizontally
 * @returns {JSX.Element}
 * @constructor
 */
const HintModal: React.FC<Props> = ({
  onClick,
  elements,
  textButton,
  horizontal = true,
}) => {
  return (
    <Modal
      isOpen
      ariaHideApp={false}
      contentLabel="Example Modal"
      className={classNames("hint-modal flex-center", {
        "hint-modal__horizontal": horizontal,
      })}
      overlayClassName="overlay overlay-dark"
    >
      <div className="hint-modal__container">
        {elements.map(({ icon, text, key }) => {
          return (
            <div className="hint-modal__element" key={key}>
              <img className="hint-modal__img" src={icon} alt={key} />
              <span className="hint-modal__text">{text}</span>
            </div>
          );
        })}
      </div>

      <span
        onClick={onClick}
        onKeyDown={onClick}
        className="text-blue  hint-modal__button"
      >
        {textButton}
      </span>
    </Modal>
  );
};

export default HintModal;
