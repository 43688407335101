import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import gameZoneSelector from "../../../store/modules/gameZone/selector";
import { getGameList, actions } from "../../../store/modules/gameZone/actions";
import RoundButton from "../../../components/buttons/RoundButton";
import home from "../../../assets/icons/home.svg";
import arrow from "../../../assets/icons/yellow_back_arrow.svg";
import childLoader from "../../../assets/icons/loaders/preloader_2_for_CSS-1.svg";
import { arcades_completed_levels, getIsPortrait } from "../../../helpers";
import childrenSelector from "../../../store/modules/children/selector";
import "./index.scss";
import cn from "classnames";
import useOrientation from "../../../hooks/useOrientation";
import GameCard from "./components/GameElement";
import useMediaPath from "../../../hooks/useMediaPath";
import { MediaPathMode } from "../../../constants/enums";
import authSelector from '../../../store/modules/auth/selector';

interface IParams {
  categoryId?: string;
}

/**
 *  List of Games in game categories.
 *
 * @returns {JSX.Element}
 * @constructor
 */
const GameList = () => {
  const path = useMediaPath(MediaPathMode.Arcades);
  const { categoryId } = useParams<IParams>();
  const history = useHistory();
  const dispatch = useDispatch();
  // TRUE because first list is always true for unregister user.
  const availableRef = useRef(true)

  const { loading, gameList } = useSelector(gameZoneSelector.getGameStore);
  const activeChildId = useSelector(childrenSelector.getActiveChildId);
  const auth = useSelector(authSelector.getAuth);

  const [orientation] = useOrientation();

  const completedLevel = localStorage.getItem(arcades_completed_levels);

  useEffect(() => {
    //
    // if (categoryId && activeChildId) {
    //   getGameList(activeChildId, +categoryId, history);
    // }

    if (auth && categoryId) {
      const childId = activeChildId ? activeChildId : 0
      dispatch(getGameList(childId, +categoryId, history));
    }

    return () => {
      dispatch(actions.setGameList(null));
    };
  }, [activeChildId, auth]);

  const getButtons = () => {
    return (
      <>
        <RoundButton
          image={home}
          onClick={() => {
            history.push("/home");
          }}
          alt="home"
          // className={`${homeClass} lessons-nav_circle position-fixed`}
          className={cn('lessons-nav_circle position-fixed ', {
            'position__left-bottom': getIsPortrait(orientation),
            'position__right-bottom': !getIsPortrait(orientation)
          })}
          // imageClassName={`lessons-nav_circle-house  lessons-nav-image ${imageHomeClass}`}
          imageClassName={cn('lessons-nav_circle-house  lessons-nav-image ', {
            'game-nav__image-transform': getIsPortrait(orientation)
          })}
        />

        <RoundButton
          image={arrow}
          onClick={() => {
            history.push('/game-categories');
          }}
          alt="arrow"
          // className={`${arrowClass} lessons-nav_circle position-fixed`}
          className={cn('lessons-nav_circle position-fixed ', {
            'position__left-top game-nav__image-arrow': getIsPortrait(orientation),
            'position__left-bottom': !getIsPortrait(orientation)
          })}
          // imageClassName={`lessons-nav-image ${imageArrowClass}`}
          imageClassName={cn('lessons-nav-image', {
            'game-nav__image-transform  game-nav__image-arrow': getIsPortrait(orientation),
            'lessons-nav_circle-arrow': !getIsPortrait(orientation)
          })
          }
        />
      </>
    );
  };

  if (loading || !gameList) {
    return (
      <div
        className="vertical-screen"
        style={{background: `center/cover no-repeat url(${path}), linear-gradient(77.19deg, #FFA31F 0%, #FFE603 100%)`}}
      >
        <div
          className="game-list flex-center"
          style={{backdropFilter: path?.includes('small') ?  'blur(5px)' : 'none'}}
        >
          <img src={childLoader} alt="loading" className="loader-icon" />
        </div>
      </div>
    );
  }

  return (
    <>
      <div
        className="vertical-screen overflow-auto"
        style={{background: `center/cover no-repeat url(${path}), linear-gradient(77.19deg, #FFA31F 0%, #FFE603 100%)`}}
      >
        <div
          style={{backdropFilter: path?.includes('small') ?  'blur(5px)' : 'none'}}
          className="game-list"
        >
          <div className="game-list__container">
            {gameList && gameList.map(({ id, name, image, isAvailable, firstLevelId }, index) => {
              if (!index) availableRef.current = true;
              const available = auth === 'authorized' ? isAvailable : availableRef.current ;
              if (auth === 'notAuthorized') {
                 availableRef.current = !!completedLevel && completedLevel.split(',')?.some(el => el === firstLevelId?.toString())
              }
              return (
                <GameCard
                  key={id}
                  gameId={id}
                  categoryId={categoryId!}
                  name={name}
                  image={image}
                  isAvailable={available} />
              )
            })}
          </div>
        </div>
      </div>
      {getButtons()}
    </>
  );
};

export default GameList;
