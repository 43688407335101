import React, { useEffect, Suspense } from "react";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import PublicRouter from "../../router/publicRouter";
import PublicRouts from "../../router/routes/publicRouts";
import { setMe } from "../../store/modules/auth/actions";
import RouteLogic from "../RouteLogic";
import { getHeightDevice, getWidthDevice } from "../../helpers";
import LoadingModal from "../../components/modals/LoadingModal";
import AuthRouter from "../../router/authRouter";
import LazyRouter from "../../router/lazyRouter";
import "../../style/index.scss";
import "./app.scss";
import Error500 from '../Error500';
import ErrorBoundary from '../../components/ErrorBoundry';
import * as Sentry from '@sentry/react';
import env from "@beam-australia/react-env";

interface IMapDispatch {
  setMe: () => void;
}

type Props = IMapDispatch;
/**
 * Main Component of App
 *
 * @param setMe - Action. Set user authorised or not
 * @returns {JSX.Element}
 * @constructor
 */
const App: React.FC<Props> = ({ setMe }) => {
  const { pathname } = useLocation();

  useEffect(() => {
    getSize();
    setMe();
  }, [setMe]);

  useEffect(() => {

    window.addEventListener("orientationchange", () => {
      setTimeout(getSize, 200);
    });

    window.addEventListener("resize", () => {
      setTimeout(getSize, 200);
    });

  }, []);

  const getSize = () => {
    const vh = getHeightDevice();
    document.documentElement.style.setProperty("--vh", `${vh}px`);

    const vw = getWidthDevice();
    document.documentElement.style.setProperty("--vw", `${vw}px`);
  };

  return (
    <>
      <RouteLogic>
        <div className="app">
          <ErrorBoundary>
            <Switch>
              <Redirect from="/:url*(/+)" to={pathname.slice(0, -1)}/>
              <Suspense fallback={<LoadingModal isForChild={false}/>}>
                <Route path="/" component={RouteLogic} exact/>
                <Route path="/error500" component={Error500} exact/>
                <PublicRouter path="/" component={PublicRouts}/>

                <AuthRouter path="/auth"/>

                <LazyRouter path="/cabinet" id="cabinet"/>

                <LazyRouter path="/settings" id="settings"/>
              </Suspense>
            </Switch>
          </ErrorBoundary>
        </div>
      </RouteLogic>
    </>
  );
};

const mapDispatchToProps = { setMe };

export default connect(null, mapDispatchToProps)(Sentry.withProfiler(App));
