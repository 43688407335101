import React, { useMemo, useRef, useState } from "react";
import LessonElement from "../LessonElement";
import { ILessonList } from "../../../../../store/modules/studyZone/types";
import { Authorization } from "../../../../../store/modules/auth/types";
import { IOnLessonClick } from "../../type";
import arrow from '../../../../../assets/icons/blue-arrow-top.svg';
import useWindowSize from '../../../../../hooks/useWindowSize';
import { isIOS, isSafari } from '../../../../../helpers';
import SuccessAlert from '../../../../../components/modals/SuccessAlert';
import { getUkrainianMonthLesson, getUkrainianMonthName } from '../../../../helpers';

interface Props {
  lessonsList: ILessonList[];
  auth: Authorization | null;
  onLessonClick: ({ lessonId, partId }: IOnLessonClick) => void;
}

/**
 *
 * @param auth{'authorized' || 'notAuthorized'} - Redux value. Show auth user or no
 * @param lessonsList{Array} - Redux value. List of lesson from api.
 * @param onLessonClick{Function} - Function redirect user to new page.
 * @returns {JSX.Element}
 * @constructor
 */

const AVAILABLE_STEP = 1

const Lessons: React.FC<Props> = ({ lessonsList, auth, onLessonClick }) => {
  const myRef = useRef<HTMLDivElement>(null);
  // This value is multiple to 5, because lessons are in row to 5 elements
  const [currentActiveId, setCurrentActiveId] = useState(0);
  const [lockedAlertText, setLockedAlertText] = useState('')
  const { width } = useWindowSize()

  const visibleRow = width! >= 992 ? 3 : 2;

  const onScrollTo = (top: number) => {
    if (myRef.current) {
      myRef.current.scrollTo({
        top,
        behavior: isIOS() || isSafari() ? 'auto' : 'smooth'
      })
    }
  }

  const getElement = useMemo(() => {
    let availableArray: number[] = [];

    const idFilter = (
      start: number,
      end: number,
      completedLevelsId: number[]
    ) => {
      const completedLevels = lessonsList.filter((item) => {
        return completedLevelsId.find((completedId) => completedId === item.id);
      });
      for (let i = start; i <= end; i++) {
        if (i > completedLevels.length + AVAILABLE_STEP) {
          break;
        }
        availableArray.push(i);
      }
    };

    if (auth === "notAuthorized") {
      let localCompleted: number[] = [];

      if (localStorage.getItem("completed_level")) {
        localCompleted = localStorage
          .getItem("completed_level")!
          .split(",")
          .map((el) => +el);
      }

      idFilter(1, lessonsList.length, localCompleted);
    }


    if (lessonsList) {
      return lessonsList.map(
        (
          { levelId, id, completed, openAt, isUnlocked, name, order, savedPartId },
          index
        ) => {
          const available = auth === "notAuthorized"
            ? isUnlocked && availableArray.some((elementIndex) => elementIndex === order)
            : isUnlocked;

          const onLockedClick = () => {
            if (auth === "authorized") {
              if (new Date () <  new Date(openAt)) {
                setLockedAlertText(`Цей урок буде розблокований ${new Date(openAt).getDate()} ${getUkrainianMonthLesson(openAt)}`)
              } else  {
                setLockedAlertText(`Пройди попередні уроки в цьому рівні щоб відкрити цей урок!`)
              }
            }
            if (auth === 'notAuthorized') {
              if (isUnlocked) {
                setLockedAlertText(`Пройди попередні уроки щоб відкрити цей!`)
              }
              if (!isUnlocked) {
                setLockedAlertText(`Зареєструйся для того щоб відкрити більше уроків!`)
              }
            }
          }
          return (
            <LessonElement
              key={id}
              levelId={levelId}
              id={id}
              name={index + 1}
              completed={completed}
              available={available}
              savedPartId={savedPartId}
              onLessonClick={onLessonClick}
              onLockedClick={onLockedClick}
              auth={auth}
              isScrollToMe={currentActiveId + 1 === index}
              onScrollTo={onScrollTo}
            />
          );
        }
      );
    }

    return null;
  }, [lessonsList, currentActiveId]);

  // @ts-ignore
  const onScroll = (isTop: boolean) => {
    if (isTop) {
      setCurrentActiveId((value) => value - 5);
    }
    if (!isTop) {
      setCurrentActiveId((value) => value + 5);
    }
  };

  return (
    <div className="lessons__elements">
      <div className="lessons__nav-button top">
        {lockedAlertText && (
          <SuccessAlert text={lockedAlertText} onClick={() => setLockedAlertText('')}/>
        )}

        {currentActiveId !== 0 && (
          <span className="lessons__nav-arrow top" onClick={() => onScroll(true)}>
						<img src={arrow} alt="arrow"/>
          </span>
        )}
      </div>
      <div ref={myRef} className="lessons-grid">
        {getElement}
      </div>
      <div className="lessons__nav-button bottom">
        {Math.ceil(lessonsList.length / 5) - visibleRow > currentActiveId / 5 && (
          <span className="lessons__nav-arrow bottom" onClick={() => onScroll(false)}>
						<img src={arrow} alt="arrow"/>
          </span>
        )}
      </div>
    </div>
  );
};

export default Lessons;
