import { Authorization } from "../../../store/modules/auth/types";
import { ILesson } from "../../../store/modules/studyZone/types";
import { History } from "history";
import {
  active_child_id,
  animation_cub,
  animation_item,
  completed_level,
  removeLocalProgressById
} from '../../../helpers';
import { UnregisterSaveProgressMode } from '../../../constants/enums';
import { Dispatch } from 'redux';

export const completedLesson = (
  auth: Authorization | null,
  lessonId: string,
  lesson: ILesson,
  setLessonCompleted: any,
  redirectBack: (wasCompleted: boolean) => void,
  history: History,
  dispatch: Dispatch<any>
) => {
  if (auth === "authorized") {
    dispatch(setLessonCompleted(
      lessonId,
      localStorage.getItem(active_child_id),
      redirectBack,
      lesson.completed,
      history
    ));
  }
  if (auth === "notAuthorized") {
    const completedLevels = localStorage.getItem(completed_level);
    removeLocalProgressById(UnregisterSaveProgressMode.Lesson, lessonId);

    if (completedLevels) {
      const isCompleted = completedLevels
        .split(",")
        .some((el) => el === lessonId);

      const lengthCompleted = completedLevels
        .split(",").length;

      if (!isCompleted) {
        localStorage.setItem(
          completed_level,
          `${completedLevels},${lessonId}`
        );

        if (
          lengthCompleted === 10 ||
          lengthCompleted === 12 ||
          lengthCompleted === 13
        ) {
          sessionStorage.setItem(animation_cub, "1");
        }

        sessionStorage.setItem(animation_item, lessonId);
        redirectBack(false);
      } else {
        redirectBack(true);
      }
    } else {
      localStorage.setItem(completed_level, lessonId);
      sessionStorage.setItem(animation_item, lessonId);
      redirectBack(false);
    }
  }
};

export const getApiPath = (
  auth: Authorization | null,
  lessonId: string | undefined
) => {
  if (auth === "authorized") {
    return `lessons/${lessonId}/child/${localStorage.getItem(
      "active_child_id"
    )}`;
  }
  if (auth === "notAuthorized") {
    return `lessons/${lessonId}`;
  }
  return null;
};
