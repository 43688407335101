import { AppStateType } from "../../rootReducer";

const getSingleFieldPage = (state: AppStateType) => state.form.singleFieldForm;
const getSignInFormData = (state: AppStateType) => state.form.signIn;
const getVerifyPhoneFormData = (state: AppStateType) => state.form.verifyPhone;
const getLoginFormData = (state: AppStateType) => state.form.Login;
const getResetFormData = (state: AppStateType) => state.form.reset;
const getResetPasswordFormData = (state: AppStateType) =>
  state.form.resetPassword;
const getKeyword = (state: AppStateType) => state.form.keyword;

export default {
  getSingleFieldPage,
  getSignInFormData,
  getLoginFormData,
  getResetFormData,
  getResetPasswordFormData,
  getKeyword,
  getVerifyPhoneFormData
};
