import { ActionsType, IArcadeLevel, ICategories, IGame, IGameList } from "./types";

const InitialState = {
  loading: false,
  categories: null as ICategories[] | null,
  gameList: null as IGameList[] | null,
  gameData: null as IGame | null,
  arcadeLevels: null as IArcadeLevel[] | null
};

type InitialStateType = typeof InitialState;

export const gameZoneReducer = (
  state = InitialState,
  action: ActionsType
): InitialStateType => {
  switch (action.type) {
    case "gameZone/GAME_ZONE_LOADING":
      return {
        ...state,
        loading: action.value,
      };
    case "gameZone/SET_CATEGORIES":
      return {
        ...state,
        categories: action.payload,
      };
    case "gameZone/SET_GAME_LIST":
      return {
        ...state,
        gameList: action.payload,
      };
    case "gameZone/SET_GAME_DATA":
      return {
        ...state,
        gameData: action.payload,
      };
    case "gameZone/SET_ARCADE_LEVEL":
      return {
        ...state,
        arcadeLevels: action.payload
      }
    default:
      return state;
  }
};
