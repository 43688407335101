import { ActionsType } from './types';

const InitialState = {
	isError: false
}

export const nativeReducer = (
	state = 	InitialState,
		action: ActionsType
) => {
	switch (action.type) {
		case "native/SET_ERROR":
			return {
				...state,
				isError: action.value
			}
		default:
			return state;
	}
}
