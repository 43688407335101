import request from "../../request";
import { ILevelsPage, ISaveLessonProgressOptions } from "./types";

export const getLevelsRequest = (path: string, params: ILevelsPage) => {
	return request.get(path, params);
};

export const getLessonListRequest = (path: string) => {
	return request.get(path);
};

export const getLessonRequest = (path: string) => {
	return request.get(path);
};

export const setLessonCompletedRequest = (
	lessonId: number,
	childId: number
) => {
	return request.post(`lessons/${lessonId}/child/${childId}/mark-as-completed`);
};

export const getCoursesDetailsRequest = (courseId: string | null) => {
	return request.get(`courses/${courseId}`);
};

export const saveLessonProgressRequest = ({ childId, partId }: ISaveLessonProgressOptions) => request.keepAlivePost(
	`lessons/${childId}/save-progress-part`,
	{ partId }
)

export const getLearnBookRequest = (
	childId: number | null,
	courseId: string | null
) => {
	return request.get(`learn-books/${courseId}/${childId}`);
};
