import { ActionsType, ISounds } from './types';

const InitialState = {
	sounds: null as ISounds | null,
	loading: false as boolean
}

type InitialStateType = typeof InitialState;

export const mediaReducer = (
	state = InitialState,
	action: ActionsType
): InitialStateType => {
	switch (action.type) {
		case 'media/SET_LOADING':
			return {
				...state,
				loading: action.value,
			};
			case 'media/SET_SOUNDS':
			return {
				...state,
				sounds: action.payload,
			};
		default:
			return state;
	}
}
