import React from "react";
import { connect } from "react-redux";
import ReduxSingleFieldPageForm from "./SingleFieldPageForm";
import formSelector from "../../../store/modules/form/selector";
import BackButton from "../../buttons/BackButton";
import { AppStateType } from "../../../store/rootReducer";

interface IProps {
  formData?: any;
  onSubmit: (values: any) => void;
  title: string;
  fieldText?: string;
  placeholder: string;
  buttonText: string;
  isBackButton?: boolean;
  clickBack?: () => void | undefined;
  isBlueButton?: boolean;
  blueButtonText?: string;
  blueClick?: () => void;
  validation: any;
  name?: string;
  isSingleText?: boolean;
  singleText?: string;
}

/**
 *
 * @param formData{Object} - Redux value. Value from form (redux-form)
 * @param onSubmit{function} - Submit form.
 * @param title {string} - Title in page.
 * @param fieldText{string} - Text between field and button.
 * @param placeholder{string} - Placeholder text in field
 * @param buttonText{string} - Text in button.
 * @param isBackButton{boolean} - Shows will be back button or no
 * @param clickBack{function} - What happens when a user clicks the Back button
 * @param isBlueButton{boolean} - Show will be blue button (under mainButton).
 * @param blueButtonText{function} - text in blue button
 * @param blueClick{function} - What happens when a user clicks the blue button
 * @param validation{Object || array} - Validation function for field
 * @param name{string} - Name of Field in redux-form field
 * @param isSingleText - Shows will be text between field and main button.
 * @param singleText - Text between field and main Button
 * @returns {JSX.Element}
 * @constructor
 */

const SingleFieldPage: React.FC<IProps> = ({
  formData,
  onSubmit,
  title,
  fieldText,
  placeholder,
  buttonText,
  isBackButton = false,
  clickBack,
  isBlueButton = false,
  blueButtonText,
  blueClick,
  validation,
  name,
  isSingleText = false,
  singleText,
}) => {
  const getBlueLink = () => {
    if (isBlueButton) {
      return (
        <div
          onClick={blueClick}
          onKeyDown={blueClick}
          className="auth__text-blue margin-top_50"
        >
          {blueButtonText}
        </div>
      );
    }
    return null;
  };

  return (
    <>
      {isBackButton && clickBack && <BackButton onClick={clickBack} />}
      <div className="main__wrapper">
        <div className="auth__title text-center margin-bottom_40">{title}</div>
        <ReduxSingleFieldPageForm
          onSubmit={onSubmit}
          formError={formData?.syncErrors}
          fieldText={fieldText}
          placeholder={placeholder}
          buttonText={buttonText}
          validation={validation}
          name={name}
          singleText={singleText}
          isSingleText={isSingleText}
        />

        {getBlueLink()}
      </div>
    </>
  );
};

const mapStateToProps = (state: AppStateType) => ({
  formData: formSelector.getSingleFieldPage(state),
});

export default connect(mapStateToProps)(SingleFieldPage);
