import Cookies from "js-cookie";

import { store } from "./store";
import { actions } from "./modules/auth/actions";
import { actions as nativeAction } from "./modules/native/actions";
import { actions as childAction } from "./modules/children/actions";
import { active_child_id } from "../helpers";
import * as Sentry from "@sentry/browser";
import env from "@beam-australia/react-env";

type Token = string | null;

type Method = "GET" | "DELETE" | "POST" | "PUT" | "PATCH";

const getUserToken = async () => {
  const state = store.getState();
  let tokenAccess: Token = "";
  let token: Token;

  const createToken = () => {
    const tokenType = Cookies.get("token_type") || state.auth.token || null;
    let token: Token = `${tokenType} ${tokenAccess}`;
    if (tokenAccess === "null") token = null;
    return token;
  };

  if (state.auth.token) {
    tokenAccess = state.auth.token;
    token = createToken();
  } else if (Cookies.get("auth_token")) {
    tokenAccess = Cookies.get("auth_token")!;
    token = createToken();
  } else {
    tokenAccess = null;
    token = null;
  }

  return token ? `${token}` : null;
};

export function getBaseOptions(method: string, optionHeader: any) {
  const options: any = {
    headers: {
      accept: "application/json",
      "content-Type": "application/json",
      ...optionHeader,
    },
    // keepalive: true
  };
  if (optionHeader) {
    options.headers = optionHeader;
  }

  options.method = method;

  return options;
}

export const authorizeRequest = async (options: any) => {
  const token = await getUserToken();

  if (token) options.headers.Authorization = token;
  return options;
};

function getPathWithQueryString(path: string, params: any = {}) {
  const esc = encodeURIComponent;
  const query = Object.keys(params)
    .filter((k) => params[k])
    .map((k) => `${esc(k)}=${esc(params[k])}`)
    .join("&");
  return query ? `${path}?${query}` : path;
}

export const getRequestUrl = (api: string, path: string) => {
  return `${api}/${path}`;
};

export const removeTokens = () => {
  Cookies.remove("auth_token", { domain: env("DOMAIN") });
  Cookies.remove("refresh_token", { domain: env("DOMAIN") });
  Cookies.remove("token_type", { domain: env("DOMAIN") });
  localStorage.removeItem(active_child_id);
};

const getNewToken = async (refreshToken: string) => {
  try {
    // delete token from localStorage and state. So that there are no loops.
    store.dispatch(actions.deleteToken());
    removeTokens();

    const params = {
      refresh_token: refreshToken,
    };

    const res = await request.post("token/refresh", params);

    if (
      res.status === 401 ||
      res.status === 400 ||
      res.status === 422 ||
      res.status === 500
    ) {
      store.dispatch(childAction.setActiveChild(null));
    }

    if (res.status === 200) {
      store.dispatch(
        actions.saveToken(
          res.data.access_token,
          res.data.refresh_token,
          res.data.token_type
        )
      );
    }
  } catch (error) {
    console.log(error);
  }
};

const base: Function = async (
  path: string,
  method: Method,
  customOptions: any,
  optionHeader: any
) => {
  const API = env("BACKEND_API_BASE_URL");
  let options: any = getBaseOptions(method, optionHeader);
  const auth = await authorizeRequest(options);
  options = Object.assign(options, auth, customOptions);

  return fetch(getRequestUrl(API!, path), options)
    .then(async (response) => {
      if ((response.ok || response.status === 422) && response.status !== 204) {
        return { status: response.status, ...(await response?.json()) };
      }

      const refreshToken = Cookies.get("refresh_token");

      if (response.status === 401 && refreshToken) {
        // Checks token in localStorage. If it is try refresh old token and get data with new token
        await getNewToken(refreshToken);
        return base(path, method, customOptions);
      }

      if (response.status === 500) {
        store.dispatch(nativeAction.setError(true));
        Sentry.captureException(response);
      }

      return response;
    })
    .catch((err) => {
      console.log(err);
      store.dispatch(nativeAction.setError(true));
      Sentry.captureException(err);
    });
};

const request = {
  get: (path: string, params?: any) => {
    const pathWithParams = getPathWithQueryString(path, params);
    return base(pathWithParams, "GET", {});
  },
  delete: (path: string, params?: any) => {
    const pathWithParams = getPathWithQueryString(path, params);
    return base(pathWithParams, "DELETE", {});
  },
  post: (path: string, params?: any) => {
    const options = { body: JSON.stringify(params) };
    return base(path, "POST", options);
  },
  keepAlivePost: (path: string, params?: any) => {
    const options = { body: JSON.stringify(params), keepalive: true };
    return base(path, "POST", options);
  },
  postData: (path: string, params: any, option: any) => {
    return base(path, "POST", { body: params }, option);
  },
  put: (path: string, params?: any) => {
    const options = { body: JSON.stringify(params) };
    return base(path, "PUT", options);
  },
  putData: (path: string, params?: any, option?: any) => {
    return base(path, "PUT", { body: params }, option);
  },
  patch: (path: string, params?: any) => {
    const options = { body: JSON.stringify(params) };
    return base(path, "PATCH", options);
  },
};

export default request;
