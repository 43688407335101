import { AppStateType } from "../../rootReducer";

const getLoading = (state: AppStateType) => state.gameZone.loading;
const getCategories = (state: AppStateType) => state.gameZone.categories;
const getGameList = (state: AppStateType) => state.gameZone.gameList;
const getGameData = (state: AppStateType) => state.gameZone.gameData;
const getArcadeLevels = (state: AppStateType) => state.gameZone.arcadeLevels;
const getGameStore = (state: AppStateType) => state.gameZone

export default {
  getLoading,
  getCategories,
  getGameList,
  getGameData,
  getArcadeLevels,
  getGameStore
};
