import React from "react";
import { ReactComponent as ArrowBack } from "../../../assets/icons/yellow_back_arrow.svg";
import "./backButton.scss";

type PropType = {
  onClick: () => void;
};
/**
 *  Back auth Button. Round white button with yellow arrow. Have absolute position
 *
 * @param onClick {function} - return event in props
 * @returns {JSX.Element}
 * @constructor
 */
const BackButton: React.FC<PropType> = ({ onClick }) => {
  return (
    <div className="back-button-container">
      <div className="round-button flex-center lessons-nav_circle" onClick={onClick} onKeyDown={onClick}>
        <ArrowBack className="lessons-nav-image lessons-nav_circle-arrow" />
      </div>
    </div>
  );
};

export default BackButton;
