import { AppStateType } from "../../rootReducer";

const getAuth = (state: AppStateType) => state.auth.auth;
const getLoading = (state: AppStateType) => state.auth.loading;
const getIsShowSuccessAlert = (state: AppStateType) =>
  state.auth.isShowSuccessAlert;

export default {
  getAuth,
  getLoading,
  getIsShowSuccessAlert,
};
