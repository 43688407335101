import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { getCoursesDetails } from "../../store/modules/studyZone/actions";
import gameZoneSelector from "../../store/modules/studyZone/selector";
import { getChildDetails } from "../../store/modules/children/actions";
import childrenSelector from "../../store/modules/children/selector";
import whiteStar from "../../assets/icons/white_star.svg";
import { getNotAuthCubList, getWhiteCubsList } from "../helpers";
import RoundButton from "../../components/buttons/RoundButton";
import home from "../../assets/icons/home.svg";
import authSelector from "../../store/modules/auth/selector";
import { getLocalCompletedLength } from "../../helpers";
import childLoader from "../../assets/icons/loaders/preloader_2_for_CSS-1.svg";
import { AppStateType } from "../../store/rootReducer";
import { History } from "history";
import { IChild } from "../../store/modules/children/types";
import { ICourse } from "../../store/modules/studyZone/types";
import { Authorization } from "../../store/modules/auth/types";
import { ICub } from "../../types";
import "./achievements.scss";

interface IMapState {
  activeChild: null | IChild;
  coursesDetails: ICourse | null;
  gameZoneLoading: boolean;
  childrenLoading: boolean;
  auth: Authorization | null;
  activeChildId: number | null;
}

interface IMapDispatch {
  getCoursesDetails: () => void;
  getChildDetails: (childId: number, history: History) => any;
}

type Props = IMapDispatch & IMapState;
/**
 *
 * @param getCoursesDetails{function} - Action. Get information about course.
 * @param activeChildId{number} - Redux value. Active Child Id.
 * @param coursesDetails{Object} - Redux value. Details of curse lesson count and level count
 * @param gameZoneLoading{boolean} - Redux value. Show when need show to load.
 * @param getChildDetails{function} - Action. Get details of course (coursesDetails).
 * @param activeChild{Object} - Redux value. Details information about child
 * @param childrenLoading{boolean} - Redux value. Show when need show to load.
 * @param auth {'authorized' || 'notAuthorized'} - Redux value.
 * @returns {JSX.Element}
 * @constructor
 */
const Achievements: React.FC<Props> = ({
  getCoursesDetails,
  coursesDetails,
  gameZoneLoading,
  getChildDetails,
  activeChild,
  childrenLoading,
  auth,
  activeChildId,
}) => {
  const history = useHistory();
  const { t } = useTranslation();
  const [cubs, setCubs] = useState<ICub[] | null>(null);
  const localCompleted = useRef(getLocalCompletedLength());

  useEffect(() => {
    if (auth === "authorized" && activeChildId) {
      getChildDetails(activeChildId, history);
    }

    getCoursesDetails();
  }, [auth]);

  useEffect(() => {
    if (auth === "authorized" && activeChild && coursesDetails) {
      const cubs = getWhiteCubsList(activeChild, coursesDetails);
      setCubs(cubs);
    }

    if (auth === "notAuthorized" && coursesDetails) {
      const cubs = getNotAuthCubList(localCompleted.current, coursesDetails);
      setCubs(cubs);
    }
  }, [auth, coursesDetails, activeChild]);

  const isLoading = () => {
    if (
      auth === "authorized" &&
      (childrenLoading ||
        !activeChild ||
        gameZoneLoading ||
        !coursesDetails ||
        !cubs)
    ) {
      return true;
    }

    if (gameZoneLoading || !coursesDetails || !cubs) {
      return true;
    }

    return null;
  };

  const getCubs = () => {
    if (cubs) {
      return cubs.map(({ key, icon, count }) => {
        return (
          <div className="achieve__cub-element" key={key}>
            <img src={icon} alt="cub" className="achieve__cub-img" />
            <span className="achieve__cub-count">{count}</span>
          </div>
        );
      });
    }
  };

  const getCompletedStar = () => {
    if (auth === "authorized" && activeChild?.progress) {
      return activeChild.progress.completed_lessons;
    }

    if (auth === "notAuthorized") {
      return localCompleted.current;
    }

    return null;
  };

  if (isLoading()) {
    return (
      <div className="vertical-screen">
        <div className="achievements">
          <img src={childLoader} alt="loading" className="loader-icon" />
        </div>
      </div>
    );
  }

  return (
    <div className="vertical-screen">
      <div className="achievements">
        <div className="achieve__star-container">
          <img
            src={whiteStar}
            alt="star"
            className="achieve__star starAnimation"
          />
          <div className="achieve__star-text">
            <strong>{getCompletedStar()}</strong> /{" "}
            {coursesDetails?.lessons_count}
          </div>
        </div>
        <div className="achieve__cub-container">
          <div className="achieve__cub-text margin-bottom_50">
            {t("Achievements.cubText")}
          </div>
          <div className="achieve__cub-elements">{cubs && getCubs()}</div>
        </div>
      </div>
      <RoundButton
        image={home}
        onClick={() => {
          history.push("/home");
        }}
        alt="home"
        className="position__left-bottom lessons-nav_circle"
        imageClassName="lessons-nav_circle-house lessons-nav-image"
      />
    </div>
  );
};

const mapStateToProps = (state: AppStateType): IMapState => ({
  activeChild: childrenSelector.getActiveChild(state),
  activeChildId: childrenSelector.getActiveChildId(state),
  coursesDetails: gameZoneSelector.getCoursesDetails(state),
  gameZoneLoading: gameZoneSelector.getLoading(state),
  childrenLoading: childrenSelector.getLoading(state),
  auth: authSelector.getAuth(state),
});

const mapDispatchToProps: IMapDispatch = {
  getCoursesDetails,
  getChildDetails,
};

export default connect(mapStateToProps, mapDispatchToProps)(Achievements);
