import React from "react";
import classNames from "classnames";
import { useHistory } from "react-router-dom";
import { ReactComponent as LogInIcon } from "../../../../assets/icons/home/log-in.svg";
import { IProfile } from "../../../../store/modules/profile/types";
import { Authorization } from "../../../../store/modules/auth/types";

interface Props {
  profile: IProfile | null;
  auth: Authorization | null;
  setRegisterModal: (value: boolean) => void;
}

/**
 *
 * @param profile{Object} - Redux value. Object with data of profile
 * @param auth{string} - Show auth user or not
 * @param setRegisterModal - Set when need show register modal.
 * @returns {JSX.Element}
 * @constructor
 */
const CabinetButton: React.FC<Props> = ({
  profile,
  auth,
  setRegisterModal,
}) => {
  const history = useHistory();

  const getFirstLatter = () => {
    if (profile?.children.length) {
      return profile.children
        .find((item) => item.id === +localStorage.getItem("active_child_id")!)
        ?.name.trim()
        .split("")[0]
        .toUpperCase();
    }

    return null;
  };

  const getContent = () => {
    if (auth === "authorized") {
      const activeChildId = +localStorage.getItem("active_child_id")!;
      if (
        profile?.children?.length &&
        profile?.children.find((item) => item.id === activeChildId)?.avatar
      ) {
        return (
          <img
            className="child__image"
            src={
              profile.children.find((item) => item.id === activeChildId)
                ?.avatar!
            }
            alt="avatar"
          />
        );
      }

      return getFirstLatter();
    }

    return <LogInIcon className="login__image" />;
  };

  const clickHandler = () => {
    if (auth === "authorized") {
      history.push("/enter-keyword");
    }

    if (auth === "notAuthorized") {
      setRegisterModal(true);
    }
  };

  return (
    <div
      className={classNames("home__profile flex-center", {
        bgc_blue: auth === "notAuthorized",
        bgc_coral: auth === "authorized",
      })}
      onClick={clickHandler}
      onKeyDown={clickHandler}
    >
      <span className="home__profile-span flex-center">{getContent()}</span>
    </div>
  );
};

export default CabinetButton;
