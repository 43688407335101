import {
  ActionsType,
  ICourse,
  ILearnBook,
  ILesson,
  ILessonList,
  ILevels,
} from "./types";

type InitialStateType = typeof InitialState;

const InitialState = {
  loading: false,
  levels: null as null | ILevels,
  lessonsList: null as null | ILessonList[],
  lesson: null as null | ILesson,
  prevComplete: null as null | number,
  coursesDetails: null as null | ICourse,
  booksOfLearn: null as null | ILearnBook[],
};

export const studyZoneReducer = (
  state = InitialState,
  action: ActionsType
): InitialStateType => {
  switch (action.type) {
    case "studyZone/SET_LEVELS":
      return {
        ...state,
        levels: action.payload,
      };
    case "studyZone/STUDY_ZONE_LOADING":
      return {
        ...state,
        loading: action.value,
      };
    case "studyZone/SET_LESSONS_LIST":
      return {
        ...state,
        lessonsList: action.payload,
      };
    case "studyZone/SET_LESSON":
      return {
        ...state,
        lesson: action.payload,
      };
      case "studyZone/CLEAR_LESSON":
      return {
        ...state,
        lesson: null,
      };
    case "studyZone/CLEAR_LESSONS":
      return {
        ...state,
        lessonsList: null,
      };
    case "studyZone/CLEAR_LEVELS":
      return {
        ...state,
        levels: null,
      };
    case "studyZone/SET_COMPLETE_LESSONS":
      return {
        ...state,
        prevComplete: action.prevComplete,
      };
    case "studyZone/SET_COURSES_DETAILS":
      return {
        ...state,
        coursesDetails: action.payload,
      };
    case "studyZone/SET_LEARN_BOOK":
      return {
        ...state,
        booksOfLearn: action.payload,
      };
    default:
      return state;
  }
};
