import { AppStateType } from "../../rootReducer";

export const getInfoSubscription = (state: AppStateType) =>
  state.payment.subCourseInfo;
export const getInfoLoading = (state: AppStateType) => state.payment.loading;
export const getArcadesInfo = (state: AppStateType) =>
  state.payment.subArcadesInfo;
export const getSubAccesses = (state: AppStateType) =>
  state.payment.subAccesses;

export default {
  getInfoSubscription,
  getInfoLoading,
  getArcadesInfo,
  getSubAccesses,
};
