import React from "react";
import { Field, InjectedFormProps, reduxForm } from "redux-form";
import AuthInput from "../../../inputs/AuthInputs";
import MainButton from "../../../buttons/MainButton";

interface IOwnProperty {
  fieldText: string | undefined;
  formError: any;
  placeholder: string;
  buttonText: string;
  validation: any;
  name?: string;
  isSingleText?: boolean;
  singleText?: string;
}

interface IFormValue {
  name: string;
}

type Props = InjectedFormProps<IFormValue, IOwnProperty> & IOwnProperty;

/**
 *
 * @param handleSubmit{function} - Function of Redux-form for submitting
 * @param formError{Object} - Object with error in form
 * @param fieldText{string} - Text between field and button.
 * @param placeholder{string} - Placeholder text in field
 * @param buttonText{string} - Text in button.
 * @param validation{Object || array} - Validation function for field
 * @param name{string} - Name of Field in redux-form field
 * @param isSingleText - Shows will be text between field and main button.
 * @param singleText - Text between field and main Button
 * @returns {JSX.Element}
 * @constructor
 */
const SingleFieldPageForm: React.FC<Props> = ({
  handleSubmit,
  formError,
  fieldText,
  placeholder,
  buttonText,
  validation,
  name = "email",
  isSingleText,
  singleText,
}) => {
  const getSingleText = () => {
    if (isSingleText) {
      return <div className="reset__text margin-top_30">{singleText}</div>;
    }
    return null;
  };

  return (
    <form onSubmit={handleSubmit} className="all-width">
      <div className="margin-bottom_40">
        {fieldText && <p className="sign__form-label">{fieldText}</p>}
        <Field
          name={name}
          component={AuthInput}
          type="text"
          placeholder={placeholder}
          validate={validation}
        />
        {getSingleText()}
      </div>
      <MainButton
        readOnly={Boolean(formError)}
        type="submit"
        text={buttonText}
        className={formError ? "login__button-gray" : "login__button-green"}
      />
    </form>
  );
};

const ReduxSingleFieldPageForm = reduxForm<IFormValue, IOwnProperty>({
  form: "singleFieldForm",
})(SingleFieldPageForm);

export default ReduxSingleFieldPageForm;
