import { ActionsType, Authorization } from "./types";

const InitialState = {
  loading: false,
  token: null as null | string,
  refreshToken: null as null | string,
  auth: null as null | Authorization,
  tokenType: null as null | string,
  isShowSuccessAlert: false,
};

export type InitialStateType = typeof InitialState;

export const authReducer = (
  state = InitialState,
  action: ActionsType
): InitialStateType => {
  switch (action.type) {
    case "auth/SAVE_TOKENS":
      return {
        ...state,
        token: action.token,
        refreshToken: action.refreshToken,
        tokenType: action.tokenType,
        auth: "authorized",
      };
    case "auth/SET_NOT_AUTHORIZED":
      return {
        ...state,
        auth: "notAuthorized",
      };
    case "auth/DELETE_TOKENS":
      return {
        ...state,
        token: null,
        refreshToken: null,
        tokenType: null,
        auth: "notAuthorized",
      };
    case "auth/LOADING":
      return {
        ...state,
        loading: action.value,
      };
    case "auth/SET_SUCCESS_ALERT":
      return {
        ...state,
        isShowSuccessAlert: action.value,
      };
    default:
      return state;
  }
};
