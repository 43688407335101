import React from "react";
import { Route } from "react-router-dom";
import Home from "../../pages/Home";
import Levels from "../../pages/studyZone/Levels";
import Lessons from "../../pages/studyZone/LessonsList";
import Lesson from "../../pages/studyZone/Lesson";
import EnterKeyword from "../../pages/settings/EnterKeyword";
import Achievements from "../../pages/Achievements";
import BookOfStart from "../../pages/BookOfStars";
import GameCategories from "../../pages/gameZone/GameCategories";
import GameList from "../../pages/gameZone/GameList";
import Game from "../../pages/gameZone/Game";
import GameLevels from '../../pages/gameZone/GameLevels';

const PublicRouts = () => {
  return (
    <>
      <Route path="/home" component={Home} exact />
      <Route path="/levels" component={Levels} exact />
      <Route path="/levels/:id" component={Lessons} exact />
      <Route
        path="/levels/:levelId/lessons/:lessonId"
        component={Lesson}
        exact
      />
      <Route path="/enter-keyword" component={EnterKeyword} exact />
      <Route path="/achievements" component={Achievements} exact />
      <Route path="/book-of-stars" component={BookOfStart} exact />
      <Route path="/game-categories" component={GameCategories} exact />
      <Route path="/game-categories/:categoryId" component={GameList} exact />

      {/*<Route*/}
      {/*  path="/game-categories/:categoryId/game/:gameId"*/}
      {/*  component={Game}*/}
      {/*  exact*/}
      {/*/>*/}

        <Route
          path="/game-categories/:categoryId/game/:gameId"
          component={GameLevels}
          exact
            />

      <Route
        path="/game-categories/:categoryId/game/:gameId/level/:levelId"
        component={Game}
        exact
      />
    </>
  );
};

export default PublicRouts;
