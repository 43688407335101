import request from "../../request";
import {
  AuthType,
  EmailType,
  getTokenOauthRequestType,
  ResetFieldsType,
} from "./types";

export const getTokenOauthRequest = (params: getTokenOauthRequestType) => {
  return request.post("social", params);
};

export const getTokenEmailRequest = (params: AuthType) => {
  return request.post("register", params);
};

export const getTokenLoginRequest = (params: AuthType) => {
  return request.post("login", params);
};

export const resetPasswordSendEmailRequest = (params: EmailType) => {
  return request.post("password/email", params);
};

export const resetPasswordRequest = (params: ResetFieldsType) => {
  return request.post("password/reset", params);
};
