import React, { memo } from "react";
import { ReactComponent as StarBoy } from "../../../../../assets/icons/boy_star.svg";
import classNames from "classnames";
import RoundButton from "../../../../../components/buttons/RoundButton";
import home from "../../../../../assets/icons/home.svg";
import ball from "../../../../../assets/icons/ball.svg";
import GameMenu from "../../../../../components/GameMenu";
import { useHistory } from "react-router-dom";

interface IProps {
  isMenuOpen: boolean;
  categoryId: string;
  isAnimation: boolean;
}

const GameCornerMenu: React.FC<IProps> = ({
  isMenuOpen,
  categoryId,
  isAnimation,
}) => {
  const history = useHistory();

  const starContainer = () => (
    <div className="game__starContainer">
      <StarBoy
        className={classNames("lesson-star game__star-showAnimated", {
          "game__star-hideAnimated": !isMenuOpen && !isAnimation,
          "lesson-game__star-boy_animation": isAnimation,
        })}
      />
    </div>
  );

  const navContainer = () => (
    <div className="game__buttonContainer">
      <RoundButton
        image={home}
        onClick={() => {
          if (isAnimation) return
          history.push("/home")
        }
        }
        alt="home"
        className={classNames(
          "lessons-nav_circle game__button-showAnimatedTop",
          {
            "game__button-hideAnimatedTop": !isMenuOpen && !isAnimation,
            "game__button-homeAnimation": isAnimation,
          }
        )}
        imageClassName="lessons-nav_circle-house lessons-nav-image"
      />
      <RoundButton
        image={ball}
        onClick={() => {
          if (isAnimation) return
          history.push(`/game-categories/${categoryId}`)
        }}
        alt="game list"
        className={classNames(
          "lessons-nav_circle game__button-showAnimatedBottom",
          {
            "game__button-hideAnimatedBottom": !isMenuOpen && !isAnimation,
            "game__button-ballAnimation": isAnimation,
          }
        )}
        imageClassName="lessons-nav-image"
      />
    </div>
  );

  return (
    <GameMenu
      isMenuOpen={isMenuOpen || isAnimation}
      starContainer={starContainer()}
      navContainer={navContainer()}
    />
  );
};

export default memo(GameCornerMenu);
